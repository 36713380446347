import React, { useContext, useEffect, useState } from 'react'
import { getFormattedDateTimeFromUTC } from '../utils/helper'
import Loader from '../components/Loader'
import { getFeedbacksByFilter } from '../apis/feedback'
import RootContext from '../context/RootContext'

import '../styles/feedbacks.css'
import { useNavigate } from 'react-router-dom'

const Feedbacks = () => {
  const navigate = useNavigate()
  const {
    currentUser
  } = useContext(RootContext)

  const [loading, setLoading] = useState(true)
  const [feedbacks, setFeedbacks] = useState([])

  useEffect(() => {
    if (currentUser?._id) {
      init()
    }
  }, [])

  const init = async () => {
    const _feedbacks = await getFeedbacksByFilter({ userId: currentUser._id, type: 'contact-feedback' })
    console.log('feedbacks: ', _feedbacks)
    setFeedbacks(_feedbacks)
    setLoading(false)
  }

  return (
    <div className='root-container contact-feedback-container'>
      <div className="tab-feedback">
        {loading ? <Loader /> : (
          <div className="feedback-list-container">
            {feedbacks.map((_it: any, _in: number) => (
              <div className={`feedback-item ${_it.from}`} key={_in}>
                <div className={`enter-feedback-header header-${_in}`}>
                  <h4 className='desc-title'>{_it.subject}</h4>
                  <h4 className="feedback-id">#{_it.feedbackId}</h4>
                </div>

                <div className="feedback-con-container">
                  <h4>{_it.name}</h4>
                  <p className='feedback-time'>{getFormattedDateTimeFromUTC(_it.createdAt)}</p>
                  <div className="feedback-content">{_it.content}</div>
                </div>

                {_it.reply && (
                  <div className="feedback-reply">
                    <p>Reply to: <strong>{_it.name} </strong> #{_it.feedbackId}</p>
                    <div className="reply-content" dangerouslySetInnerHTML={{ __html: _it.reply }}></div>
                  </div>
                )}
              </div>
            ))}
            {feedbacks.length === 0 && (
              <div className='no-feedback text-center'>
                <p>You've no feedback yet.</p>
                <button
                  className="btn btn-xl btn-yellow"
                  onClick={() => navigate('/feedback')}
                >
                  Give Feedback
                </button>
              </div>
            )}
          </div>
        )}


      </div>
    </div>
  )
}

export default Feedbacks