import React, { useEffect } from 'react'
import ToastCont from '../components/ToastCont'
import bgBottom from '../assets/signup/bg-01.png'
import '../styles/auth-layout.css'
import '../styles/game-buy-layout.css'
import { Outlet } from 'react-router-dom'

const GameBuyLayout = () => {
  useEffect(() => {
    // console.log('children: ', children)
  }, [])
  
  return (
    <>
      <ToastCont />
      <div className='layout-container game-buy-layout'>
        {/* <div className="bg-top"></div> */}
        <Outlet />
        
        {/* <div className="bg-bottom">
          <img src={bgBottom} alt="Bottom bg" />
        </div> */}
      </div>
    </>
  )
}

export default GameBuyLayout