import { useEffect, useState, useContext } from 'react'

import RootContext from '../context/RootContext'
import Games from './admin/Games'

import '../styles/admin.css'
import AdminContext from '../context/AdminContext'
import Users from './admin/Users'
import Feedbacks from './admin/Feedbacks'
import Offers from './admin/Offers'
import TermsAndServices from './admin/TermsAndServicesAdmin'
import PrivacyPolicyAdmin from './admin/PrivacyPolicyAdmin'
import { getUserFromToken } from '../apis/user'
import { useNavigate } from 'react-router-dom'
import PaymentsAdmin from './admin/PaymentsAdmin'
import ContactMessages from './admin/ContactMessages'


const Admin = () => {
  const navigate = useNavigate()
  const {
    adminView
  } = useContext(AdminContext)


  useEffect(() => {
    initialize()
  }, [])
  

  
  const initialize = async () => {
    const _user = await getUserFromToken(localStorage.getItem('token'))
    // console.log('_user form admin: ', _user)
    if (!_user) {
      navigate('/login')
    } else if (!_user.isAdmin) {
      navigate('/dashboard')
    }
  }

  const getAdminView = (): JSX.Element => {
    if (adminView === 'Games') {
      return <Games />
    } else if (adminView === 'Users') {
      return <Users />
    } else if (adminView === 'Feedbacks') {
      return <Feedbacks />
    } else if (adminView === 'Offer') {
      return <Offers />
    } else if (adminView === 'Terms & Condition') {
      return <TermsAndServices />
    } else if (adminView === 'Privacy Policy') {
      return <PrivacyPolicyAdmin />
    } else if (adminView === 'Payments') {
      return <PaymentsAdmin />
    } else if (adminView === 'Contact Us') {
      return <ContactMessages />
    } else  {
      return <></>
    }
  }
  
  return (
    <div className='root-container admin-container'>
      {getAdminView()}
    </div>
  )
}

export default Admin