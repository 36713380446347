import { useState, useEffect } from 'react'
import { getTermsAndServices } from '../apis/util'

import '../styles/terms-and-condition.css'

const TermsAndServices = () => {
  const [content, setContent] = useState('')

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {
    const _res = await getTermsAndServices()
    setContent(_res)
  }

  return (
    <div className='terms-and-condition'>
      <h1>Terms And Condition</h1>
      <div className="content" dangerouslySetInnerHTML={{__html: content}}></div>
    </div>
  )
}

export default TermsAndServices