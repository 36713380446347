export const reqConfig = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
}

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December'
]


// ------------------------------- Functions ---------------------------

export const getToday = () => {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  return yyyy + '-' + mm + '-' + dd
}

export const getDateFromISODate = (_date: string) => {
  const _dateLocal = new Date(_date)
  const dd = String(_dateLocal.getDate()).padStart(2, '0')
  const mm = String(_dateLocal.getMonth() + 1).padStart(2, '0')
  const yyyy = _dateLocal.getFullYear()
  return yyyy + '-' + mm + '-' + dd
}

export const generateYearArray = (startYear: number = 1900) => {
  const endYear = new Date().getFullYear();
  const years = [];
  for (let year = endYear; year >= startYear; year--) {
    years.push(year);
  }
  return years;
}

export const errorResponseHandler = (_data: any, toast: any, navigate: any): any => {
  if (_data.loginExpired) {
    navigate('/login')
  } else if (_data.error) {
    toast.error(_data.message || 'Something went wrong. Please try again')
  }
  return _data
}

export const getPaypalClientID = () => `${process.env.REACT_APP_ENV === 'DEV'
? process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID
: process.env.REACT_APP_ENV === 'PROD'
  ? process.env.REACT_APP_PAYPAL_CLIENT_ID // will be replaced after launching the app
  : process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID
  }`


export const getOperatingSystem = () => {
  const platform = navigator.platform.toLowerCase();

  if (platform.includes('win')) {
    return 'windows';
  } else if (platform.includes('mac')) {
    return 'mac';
  } else if (platform.includes('linux')) {
    return 'linux';
  } else if (platform.includes('android')) {
    return 'android';
  } else if (platform.includes('iphone') || platform.includes('ipad')) {
    return 'ios';
  } else {
    return 'unknown';
  }
}

export const getBaseURL = () => `${process.env.REACT_APP_ENV === 'DEV'
? process.env.REACT_APP_API_DEV_URL
: process.env.REACT_APP_ENV === 'PROD'
  ? process.env.REACT_APP_API_PROD_URL
  : process.env.REACT_APP_API_STAGING_URL
  }`

export const getSocketURL = () => `${process.env.REACT_APP_ENV === 'DEV'
? process.env.REACT_APP_SOCKET_DEV_URL
: process.env.REACT_APP_ENV === 'PROD'
  ? process.env.REACT_APP_SOCKET_PROD_URL
  : process.env.REACT_APP_SOCKET_STAGING_URL
  }`


export const getFormattedDateTimeFromUTC = (utcString: string) => {
  // const inputDateString = "2023-09-17T19:22:51.984Z";
  const date = new Date(utcString);

  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formattedDate = `${months[date.getMonth()]} ${date.getDate()} | ${formatAMPM(date)}`;

  // console.log(formattedDate);

  return formattedDate
}

export const getFormattedLastMsgTime = (utcString: string) => {
  // const inputDateString = "2023-09-17T19:22:51.984Z";
  const date = new Date(utcString);

  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formattedDate = `${formatAMPM(date)} | ${date.getDate()} ${months[date.getMonth()]}`;

  // console.log(formattedDate);

  return formattedDate
}

export const getFormattedDateYearTimeFromUTC = (utcString: string) => {
  // const inputDateString = "2023-09-17T19:22:51.984Z";
  const date = new Date(utcString);

  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formattedDate = `${formatAMPM(date)} | ${months[date.getMonth()]} ${date.getDate()} - ${date.getFullYear()}`;

  // console.log(formattedDate);

  return formattedDate
}

const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes: any = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // 0 should be displayed as 12
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${ampm}`;
}