import { useState, useContext} from 'react'

import fluxSVG from '../assets/flux.svg'
import '../styles/referral.css'
import { inviteFriends } from '../apis/user'
import RootContext from '../context/RootContext'
import { toast } from 'react-toastify'

const Referral = () => {
  const {
    currentUser
  } = useContext(RootContext)

  const [emails, setEmails] = useState('')

  const handleInviteSubmit = async () => {
    console.log('emails: ', emails)
    if (emails && emails.length) {
      const _resData = await inviteFriends(emails, currentUser?._id || '')
      console.log(_resData)
      if (_resData && _resData.success) {
        toast.success(_resData.message)
        setEmails('')
      } else {
        toast.error('Something went wrong. Please try again!')
      }
    }
  }
  return (
    <div className='root-container invite-container'>
      <h1 className="referral-title mt-4 mb-2">Referrals</h1>
      <h3 className="referral-sub-title mb-1">Refer a friend & Get <span><img src={fluxSVG} alt="Flux" /></span>5 Flux</h3>
      <p className="desc desc-1">Spread the <span className="yellow">Flux GameLand</span> Love!!!</p>
      <p className="desc desc-2">You'll get 5 Flux for each friend who join.</p>
      <p className="desc desc-3"><small>Only new users are valid for the referral.</small></p>

      <div className="emails-input">
        <textarea
          name=""
          id=""
          cols={30}
          rows={10}
          placeholder="Your Friend's emails (separated by commas)"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
        >

        </textarea>
      </div>

      <div className="invite-submit">
        <button
          className='btn btn-yellow btn-xl'
          onClick={handleInviteSubmit}
        >
          Send
        </button>
      </div>
    </div>
  )
}

export default Referral