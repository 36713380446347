export const sidebarDashboardData = [
  {
    title: 'Profile',
    path: '/profile',
  },
  {
    title: 'Avatar',
    path: '/avatar',
  },
  // {
  //   title: 'Inventory',
  //   path: '/inventory',
  // },
  // {
  //   title: 'Trade',
  //   path: '/trade',
  // },
  // {
  //   title: 'Groups',
  //   path: '/groups',
  // },
  // {
  //   title: 'Premium',
  //   path: '/premium',
  // },
  // {
  //   title: 'Blog',
  //   path: '/blog',
  // },
  {
    title: 'Official Store',
    path: '/official-store',
  },
  {
    title: 'Gift Cards',
    path: '/gift-cards',
  },
  {
    title: 'Test Item',
    path: '/gift-cards',
  },
]

export const sidebarAdminData = [
  {
    title: 'Games',
  },
  {
    title: 'Users',
  },
  {
    title: 'Feedbacks',
  },
  {
    title: 'Offer',
  },
  {
    title: 'Payments',
  },
  {
    title: 'Terms & Condition',
  },
  {
    title: 'Privacy Policy',
  },
  {
    title: 'Contact Us'
  }
]

export const topbarData = [
  
  {
    title: 'Dashboard',
    path: '/dashboard',
  },
  // {
  //   title: 'Discover',
  //   path: '/discover',
  // },
  {
    title: 'Marketplace',
    path: '/marketplace',
  },
  {
    title: 'Invite',
    path: '/invite',
  },
  {
    title: 'Contact Us',
    path: '/contact-us',
  },
  {
    title: 'Feedbacks',
    path: '/feedbacks',
  },
  {
    title: 'Admin',
    path: '/dashboard/admin',
    isAdmin: true,
  },
]


export const marketplaceSidebarCategory = [
  { title: 'All Items', value: 'all', path: '/all-items' },
  { title: 'Characters', value: 'character', path: '/characters' },
  // { title: 'Clothing', subMenus: [] },
  // { title: 'Accesories', subMenus: [] },
  // { title: 'Heads', subMenus: [] },
  // { title: 'Animations', subMenus: [] },
]


export const salesTypeFilterItems1 = [
  {
    label: 'All', 
    value: 'all'
  },
  {
    label: 'Limited',
    value: 'limited'
  },
  {
    label: 'Premium',
    value: 'premium'
  },
]
export const priceFilterItems = [
  {
    label: 'Any Price',
    value: 'any-price'
  },
  {
    label: '_min_max_',
    value: '_min_max_'
  }
]


export const sortFilterItems = [
  {
    label: 'Relevant',
    value: 'relevant'
  },
  {
    label: 'Price (High to Low)',
    value: 'high-to-low'
  },
  {
    label: 'Price (Low to Hign)',
    value: 'low-to-high'
  }
]

export const unavailableFilterItems = [
  {
    label: 'Hide',
    value: 'hide'
  },
  {
    label: 'Show',
    value: 'show'
  }
]