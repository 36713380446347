import { reqConfig } from '../utils/helper'
import axios from '../config/axios'


export const getPagingCompletedOrders = async ({
  currentPage,
  itemsPerPage,
  transactionType
}: any) => {
  const _url = '/order?currentPage=' + currentPage + '&itemsPerPage=' + itemsPerPage + '&transactionType=' + transactionType
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    // console.log('getPagingCompletedOrders res.data: ', data)
    return data?.data || {}
  } catch (_error: any) {
    console.log('getPagingCompletedOrders error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getCompletedCount = async (type: string) => {
  const _url = '/order/count?transactionType=' + type
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    // console.log('getCompletedCount res.data: ', data)
    return data?.data || {}
  } catch (_error: any) {
    console.log('getCompletedCount error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}