import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import '../styles/signup.css'
import { login } from '../apis/auth'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import RootContext from '../context/RootContext'


const Login = () => {
  const navigate = useNavigate()
  const {
    currentUser,
    setCurrentUser
  } = useContext(RootContext)

  const [searchParams, setSearchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirect')

  const [loginSubmitted, setLoginSubmitted] = useState(false)
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    
  }, [])


  const handleLogInSubmit = async () => {
    if (loginSubmitted) {
      return
    }
    if (!userName) {
      toast.warn('Please Enter User Name')
      return
    }
    if (!password) {
      toast.warn('Please enter Password')
    }

    const loginData = {
      name: userName,
      password
    }

    console.log('loginData: ', loginData)
    // return
    setLoginSubmitted(true)
    const resData = await login(loginData)
    if (resData.error) {
      toast.error(resData.message)
    } else {
      localStorage.setItem('token', resData.token)
      toast.success(resData.message)
      setCurrentUser(resData.user)

      setUserName('')
      setPassword('')
      setTimeout(() => {
        if (redirectTo === 'feedback') {
          navigate('/feedback')
        } else {
          navigate('/dashboard')
        }
        
      }, 1500)
      
    }
    
    setLoginSubmitted(false)
  }

  return (
    <div className="signup-wrapper">
      <div className="signup-form">
        <h1 className='title'>Log In and start having fun</h1>
        <div className="form-group">
          <label htmlFor="">Username / Email</label>
          <input
            type="text"
            placeholder="Don't use your real name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Password</label>
          <input
            type="password"
            placeholder="At least 8 characters"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group signup">
          <button
            className="signup-submit-btn"
            onClick={handleLogInSubmit}
            disabled={loginSubmitted}
          >
            { loginSubmitted ? 'Please wait' : 'Login' }
          </button>
        </div>
        <div className="form-group text-center">
          <p>New User? <strong><Link to={redirectTo === 'feedback' ? '/signup?redirect=feedback' : '/signup'}>Sign Up</Link></strong></p>
        </div>
      </div>
    </div>
  )
}

export default Login