import { getBaseURL, reqConfig } from '../utils/helper'
import axios from '../config/axios'

export const createPurchase = async (postBody: any, token: string) => {
  const _url = '/purchase'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }
  try {
    const { data } = await axios.post(_url, postBody, _config)
    console.log('createPurchase res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('createPurchase error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


export const getPurchasesByUserId = async (userId: string) => {
  const _url = '/purchase/user/' + userId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    console.log('getPurchasesByUserId res.data: ', data)
    return data?.data || []
  } catch (_error: any) {
    console.log('getPurchasesByUserId error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}