import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  PayPalScriptProvider,
  PayPalButtons
} from "@paypal/react-paypal-js"
import axios from '../config/axios'
import { getBaseURL, getPaypalClientID } from '../utils/helper'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { getUserFromToken, updateFluxById } from '../apis/user'
import fluxIcon from '../assets/flux.svg'
import { getProductByIdAndToken, getThumbById } from '../apis/product'
import { createPurchase } from '../apis/purchase'
import { useSocket } from '../context/SocketContext'

const GamePaypalPayment = () => {
  const clientId = getPaypalClientID()
  const navigate = useNavigate()
  const { socket } = useSocket()

  const [ searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('token')
  const fluxAmount = searchParams.get('fluxAmount')
  const productId = searchParams.get('productId')
  const redirectedFrom = searchParams.get('from')

  const observedPaymentDiv: any = useRef()

  const [isPurchaseComplete, setIsPurchanseComplete] = useState(false)
  const [usdAmount, setUsdAmount] = useState(0.0)
  const [buyFluxAmount, setBuyFluxAmount] = useState(0.0)
  const [readyForCheckout, setReadyForCheckout] = useState(false)
  const [currentUser, setCurrentUser] = useState<any>({})
  const [exitMessage, setExitMessage] = useState('')

  const [product, setProduct] = useState<any>({})
  const [productThumb, setProductThumb] = useState<any>('')

  // const initEmail = 'example@example.com'
  useEffect(() => {
    handleGettingCurrentUser()
    if (fluxAmount && parseFloat(fluxAmount)) {
      setBuyFluxAmount(parseFloat(fluxAmount))
    }
  }, [token])

  useEffect(() => {
    if (productId) {
      handleFetchProduct()
      // handleFetchThumb()
    }
  }, [productId])

  useEffect(() => {
    setUsdAmount(buyFluxAmount / 10.0)
  }, [buyFluxAmount])
  

  const onPaypalInit = (_: any, actions: any) => {
    actions.disable()
    actions.enable()
  }

  const handleFetchThumb = async () => {
    const _imgBlob: any = await getThumbById(productId || '')
    setProductThumb(_imgBlob)
  }

  const handleFetchProduct = async () => {
    const _prodcut = await getProductByIdAndToken(productId || '', token || '')
    console.log('-product: ', _prodcut)
    if (_prodcut && _prodcut._id) {
      setProduct(_prodcut)
    }
  }
  

  const handleGettingCurrentUser = async () => {
    const __user = await getUserFromToken(token)
    console.log('__user: ', __user)
    if (__user.success === false) {
      toast.warn('Invalid Token!')
    } else {
      setCurrentUser(__user)
    }
  }

  const createOrder = (data: any) => {
    const _url = '/order/create'
    const _postBody = { // will be changed
      cartItems: [],
      payAmount: usdAmount.toFixed(2)
    }
    const _config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
    return axios.post(_url, _postBody, _config)
      .then((_res: any) => {
        console.log('res: ', _res.data.orderId)
        return _res.data.orderId
      })
  }

  const approveOrder = (data: any) => {
    console.log('onApprove data: ', data)
    const _url = '/order/capture'
    const _config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
    const _approveBody: any = data
    if (product && product._id) {
      _approveBody.cartItems = [
        {
          productId: product._id,
          price: product.price
        }
      ]
    }

    if (redirectedFrom && redirectedFrom === 'buy-life') {
      _approveBody.lifeBuy = {
        amount: 1,
        price: fluxAmount
      }
    }

    return axios.post(_url, data, _config)
      .then((_res: any) => {
        console.log('res: ', _res.data)
        if (_res.data && _res.data.status === 'COMPLETED') {
          toast.success('Your Fluxs are successfully purchased!')
          setIsPurchanseComplete(true)
          
          if (socket) {
            let _socketMsg: any = {
              name: product && product._id ? 'product' : 'life',
              purchaseFrom: 'checkout',
              message: 'Done',
              userId: currentUser._id,
            }
            if (product && product._id) {
              _socketMsg.productId = productId
            }
            socket.emit('buy-from-game', _socketMsg)
          }
          
          navigate('/game/buy-close?key=flux-success')
        }
        return _res.data
      })
  }

  const handleDirectBuy = async () => {
    const _body = {
      cartItems: [
        {
          productId: product._id,
          price: product.price
        }
      ]
    }
    const _resData = await createPurchase(_body, token || '')
    console.log(_resData)

    if (_resData && _resData.success) {
      setCurrentUser(_resData.user)
      if (socket) {
        const _socketMsg = {
          name: 'product',
          purchaseFrom: 'current-balance',
          message: 'Done',
          userId: currentUser._id,
          productId
        }
        socket.emit('buy-from-game', _socketMsg)
      }
      
      navigate('/game/buy-close?key=product-success')
    } else {
      
    }
    
  }

  const handleExistingFluxBuy = async () => {
    const _body = {
      flux: buyFluxAmount * -1
    }
    const _resData = await updateFluxById(_body, currentUser._id, token || '')
    console.log('_resData: ', _resData)
    if (_resData && _resData.success) {
      setCurrentUser(_resData.user)
      if (socket) {
        const _socketMsg = {
          name: 'flux',
          purchaseFrom: 'current-balance',
          message: 'Done',
          userId: currentUser._id
        }
        socket.emit('buy-from-game', _socketMsg)
      }
      
      navigate('/game/buy-close?key=flux-success')
    }
  }

  const getProductImgSrc = () => {
    let _url = getBaseURL()
    if (product && product._id) {
      _url += `/thumb/thumb-${product._id + product.thumbExtension}`
    }
    return _url
  }
  
  return (
    <>
      <h1 className='payment-top-title'>
        Purchase Your Product
      </h1>
      <div className={`root-container buy-game-container`}>
        
        <div className="pay-details">
          <div className="pay-details-header">
            <h1 className='mb-2'>Purchase with Flux</h1>
            <span className='mb-2'>
              Current Balance:
              <strong className='ml-1 flux-strong-amount'>
                <img src={fluxIcon} alt="Flux Icon" />
                {parseFloat(currentUser.remainingFlux).toFixed(2) || 0}
              </strong>
            </span>
          </div>
          {product && product._id && (
            <div className="product-details">
              <h1 className='mb-2'>Product: {product.name}</h1>
              <div className='mb-2 product-img'>
                <img src={getProductImgSrc()} alt={product.name || ''} />
              </div>
            </div>
          )}
          
          {product && product._id && parseFloat(product.price || 0) <= parseFloat(currentUser.remainingFlux || 0) && (
            <div className="mt-3 mb-3 action-btns remaining-flux">
              <button
                className="btn btn-xl btn-yellow active"
                onClick={handleDirectBuy}
              >
                Purchase From Remaining Flux
              </button>
            </div>
          )}
          

          <label htmlFor="flux-amount">Enter Flux Amount</label>
          <input
            disabled={readyForCheckout || true}
            type="number"
            id='flux-amount'
            value={buyFluxAmount}
            onChange={(e: any) => setBuyFluxAmount(e.target.value)}
          />

          {!productId && (
            <div className="mt-3 mb-3 action-btns remaining-flux remaining-flux-2">
              <button
                className="btn btn-xl btn-yellow active"
                onClick={handleExistingFluxBuy}
                disabled={buyFluxAmount > parseFloat(currentUser.remainingFlux || 0)}
              >
                Use from my existing Flux
              </button>
            </div>
          )}

          <h1 className='mt-4'>USD Amount ${usdAmount.toFixed(2)}</h1>
          <div className="mt-3 mb-3 action-btns">
            <button
              disabled={usdAmount === 0}
              className="btn btn-xl btn-yellow active"
              onClick={() => setReadyForCheckout(true)}
            >
              Checkout
            </button>
            <button
              className="btn btn-xl btn-yellow active"
              onClick={() => {
                if (socket) {
                  const _socketMsg = {
                    message: isPurchaseComplete ? "Done": "Cancelled",
                    userId: currentUser._id,
                  }
                  socket.emit('buy-from-game', _socketMsg)
                }
                
                navigate('/game/buy-close?key=close')
              }}
            >
              {isPurchaseComplete ? 'Done' : 'Cancel'}
            </button>
          </div>
        </div>
        <div className={`paypal-container`} ref={observedPaymentDiv}>
          {readyForCheckout && (
            <div className='paypal-payment'>
            <PayPalScriptProvider options={{ clientId }}>
              <PayPalButtons
                // onInit={onPaypalInit}
                createOrder={createOrder}
                onApprove={approveOrder}
              />
            </PayPalScriptProvider>
          </div>
          )}
          
        </div>
        
        
      </div>
    </>
  )
}

export default GamePaypalPayment