import { getBaseURL, reqConfig } from '../utils/helper'
import axios from '../config/axios'

export const getTermsAndServices = async () => {
  const _url = '/util/terms-and-condition'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    console.log('getTermsAndServices res.data: ', data)
    return data?.termsAndServices || ''
  } catch (_error: any) {
    console.log('getTermsAndServices error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


export const updateTermsAndServices = async (termsAndServices: string) => {
  const _url = '/util/terms-and-condition'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.put(_url, { termsAndServices }, _config)
    console.log('updateTermsAndServices res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('updateTermsAndServices error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


export const getPrivacyPolicy = async () => {
  const _url = '/util/privacy-policy'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    console.log('getPrivacyPolicy res.data: ', data)
    return data?.privacyPolicy || ''
  } catch (_error: any) {
    console.log('getPrivacyPolicy error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


export const updatePrivacyPolicy = async (privacyPolicy: string) => {
  const _url = '/util/privacy-policy'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.put(_url, { privacyPolicy }, _config)
    console.log('updatePrivacyPolicy res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('updatePrivacyPolicy error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}