import { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import CloseIcon from '../components/icons/CloseIcon'

import RootContext from '../context/RootContext'

import defaultFemale from '../assets/dashboard/default-female.png'
import defaultMale from '../assets/dashboard/default-male.png'
import editIcon from '../assets/edit.png'
import { deleteUserById, isUserNameAvailabe, updateUserById, uploadThumb } from '../apis/user'

import '../styles/profile.css'
import CheckIcon from '../components/icons/CheckIcon'
import { toast } from 'react-toastify'
import { getCurrentUser } from '../apis/auth'
import { getBaseURL, getDateFromISODate, getFormattedDateTimeFromUTC } from '../utils/helper'
import { getPurchasesByUserId } from '../apis/purchase'
import { getFriends } from '../apis/chat'
import { useNavigate } from 'react-router-dom'

Modal.setAppElement('#root')

const Profile = () => {
  const navigate = useNavigate()
  const {
    currentUser,
    setCurrentUser
  } = useContext(RootContext)

  const [modalLabel, setModalLabel] = useState('')
  const [userName, setUserName] = useState('')
  const [isNameAvailable, setIsNameAvailable] = useState(false)
  const [userAbout, setUserAbout] = useState('')
  const [thumb, setThumb] = useState<any>('')
  const [imgKey, setImgKey] = useState(1)
  const [purchases, setPurchases] = useState<any[]>([])
  const [accountDeleteModal, setAccountDeleteModal] = useState(false)

  const [friendsCount, setFriendsCount] = useState(0)

  let typingTimeOut: any = useRef()

  useEffect(() => {
    console.log('current user: ', currentUser)
    if (currentUser && currentUser.name) {
      setUserName(currentUser.name)
      setUserAbout(currentUser.about || '')
      getFriendsCount()
      init()
    }
  }, [currentUser])

  useEffect(() => {
    if (userName && userName.length && userName !== currentUser.name) {
      clearTimeout(typingTimeOut)
      typingTimeOut.current = setTimeout(() => {
        isUserNameAvailabe(userName).then((_available) => {
          setIsNameAvailable(_available)
        }).catch((err) => {
          // console.log('error: ', err)
          setIsNameAvailable(false)
        })
      }, 500)
    }
    
  }, [userName])

  const init = async () => {
    const _purchases = await getPurchasesByUserId(currentUser._id)
    console.log('_purchases: ', _purchases)
    setPurchases(_purchases)
  }

  const handleProfileUpdateSubmit = async () => {
    let shouldUpdate = false
    let updateBody: any = {}
    if (modalLabel === 'profile-about') {
      updateBody.about = userAbout
      shouldUpdate = true
    }
    if (modalLabel === 'profile-basic' && isNameAvailable) {
      updateBody.name = userName
      shouldUpdate = true
    }

    if (shouldUpdate) {
      const updateRes: any = await updateUserById(updateBody, currentUser._id)
      console.log('updateRes: ', updateRes)
      if (updateRes && updateRes.success) {
        setCurrentUser(updateRes.user)
        setModalLabel('')
        toast.success('Profile Updated Successfully.')
      }
    }

    if (thumb) {
      const _uploadRes = await uploadThumb(thumb, currentUser._id)
      console.log('_uploadRes: ', _uploadRes)
      const _user: any = await getCurrentUser()
      if (_user && _user._id) {
        setCurrentUser(_user)
        setImgKey(imgKey + 1)
        setModalLabel('')
        setThumb('')
      } else {
      }
    }
  }

  const getProfileThumbSrc = () => {
    if (currentUser?.thumbExtension) {
      return getBaseURL() + '/profile-thumb/thumb-' + currentUser._id + currentUser.thumbExtension
    }
    return currentUser?.gender === 'Male' ? defaultMale : defaultFemale
  }

  const getFriendsCount = async () => {
    const data = await getFriends(currentUser._id);
    setFriendsCount(data?.length || '')
  }

  const handleProfileDelete = async () => {
    const data = await deleteUserById(currentUser._id)
    console.log('res data: ', data)
    if (!data.error && data.message) {
      toast.success('Account Deleted Successfully!')
      setTimeout(() => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        navigate('/login')
      }, 2000);
    } else {
      toast.error('Something Wrong! Please try again.')
    }
  }

  return (
    <div className='profile-container'>
      <Modal
        isOpen={modalLabel !== ''}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setModalLabel('')}
      >
        <div className="profile-modal-body">
          <div className="close-modal" onClick={() => setModalLabel('')}>
            <CloseIcon />
          </div>
          <h2>{modalLabel === 'profile-basic' ? 'Update Profile Info' : 'Update About Info'}</h2>
          {modalLabel === 'profile-basic' ? (
            <>
              <div className="form-group">
                <label>Enter Username</label>
                <input
                  type="text"
                  placeholder='Enter Name'
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                
                <div className="username-status">
                  {userName && userName.length && isNameAvailable ? (
                    <p className='text-end'><span><CheckIcon /></span> <span>Available</span></p>
                  ) : userName && userName.length ? (
                    <p className='text-end'><span><CloseIcon /></span> <span>Not Available</span></p>
                  ) : ''}
                </div>
              </div>
              
              <div className="form-group">
                <label>Choose Photo</label>
                <input
                  type="file"
                  accept='image/png, image/jpeg'
                  onChange={(e) => setThumb(e.target.files?.[0])}
                />
              </div>
            </>
          ) : (
              <>
                <div className="form-group">
                <label>Enter About Information</label>
                  <textarea
                    name=""
                    id=""
                    rows={5}
                    placeholder='Enter about'
                    value={userAbout}
                    onChange={(e) => setUserAbout(e.target.value)}
                  ></textarea>
              </div>
              </>
          )}
        </div>
        
        <div className="modal-footer">
          <button
            className='btn btn-yellow btn-xl'
            onClick={handleProfileUpdateSubmit}
          >
            Update
          </button>
        </div>

      </Modal>

      <Modal
        isOpen={accountDeleteModal}
        onRequestClose={() => setAccountDeleteModal(false)}
      >
        <div className="profile-modal-body">
          <div className="close-modal" onClick={() => setAccountDeleteModal(false)}>
            <CloseIcon />
          </div>
          <h2>Account Delete Confirmation</h2>
          <div className="modal-body-content">
            <p>Are you sure to delete your account?</p>
          </div>
        </div>
        
        <div className="delete-modal-footer">
          <button
            className='btn btn-yellow btn-xl'
            onClick={() => setAccountDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className='btn btn-yellow btn-xl'
            onClick={handleProfileDelete}
          >
            Confirm
          </button>
        </div>

      </Modal>

      <div className="profile-container-inner">
        <div className="profile-header desc-header">
          <div className="profile-thumb">
            <img key={imgKey} src={getProfileThumbSrc()} alt="Profile Icon" />
          </div>
          <div className="profile-desc">
            <div className="profile-desc-left">
              <h1>{currentUser?.name || ''}</h1>
              <div className="basic-info">
                <span><strong>{friendsCount}</strong> Friends</span>
                <span><strong>0</strong> Followers</span>
                <span><strong>0</strong> Following</span>
              </div>
            </div>
            <div className="profile-desc-right">
              <button
                className='btn btn-delete btn-xl'
                onClick={() => setAccountDeleteModal(true)}
              >
                Delete My Account
              </button>
            </div>
          </div>
          <div
            className="edit-icon"
            onClick={() => setModalLabel('profile-basic')}
          >
            <img src={editIcon} alt="Edit" />
          </div>
        </div>
        <div className="about-header">
          <h4 className='desc-header'>About</h4>
          <div
            className="edit-icon"
            onClick={() => setModalLabel('profile-about')}
          >
            <img src={editIcon} alt="Edit" />
          </div>
        </div>
        <div className="about-desc">
          <p>{currentUser.about || ''}</p>
        </div>
        <div className="purchase-history-header">
          <h4 className='desc-header'>Purchase History</h4>
        </div>
        <div className="purchase-history-body">
          <div className="purchase-history-table">
            <div className="purchase-history-table-row">
              <div className="table-cell title">
                <strong>DATE</strong>
              </div>
              <div className="table-cell title">
                <strong>PRODUCT NAME</strong>
              </div>
              <div className="table-cell title">
                <strong>TYPE</strong>
              </div>
              <div className="table-cell title">
                <strong>PRICE</strong>
              </div>
            </div>


            {purchases.map((_it: any, _in) => (
              <div className='purchase-history-table-row' key={_in}>
                <div className="table-cell">
                  <span>{getDateFromISODate(_it.createdAt)}</span>
                </div>
                <div className="table-cell">
                  <span>{_it.productId?.name || ''}</span>
                </div>
                <div className="table-cell">
                  <span>{_it.productId?.category}</span>
                </div>
                <div className="table-cell">
                  <span>{_it.fluxAmount}</span>
                </div>
                
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile