import { useContext, useState, useEffect } from 'react'
import RootContext from '../../context/RootContext'

import Loader from '../../components/Loader'
import { getFormattedDateTimeFromUTC } from '../../utils/helper'

import { createContactMessage, getAllContactMessages, deleteContactMessageById, updateContactMessageById } from '../../apis/contactUs'
import { toast } from 'react-toastify'


import '../../styles/admin-contact-message.css'
import ReactModal from 'react-modal'
import CloseIcon from '../../components/icons/CloseIcon'

const ContactMessages = () => {
  const {
    currentUser
  } = useContext(RootContext)
  
  
  const [contactMessages, setContactMessages] = useState<any>([])
  const [contactMessageLoading, setContactMessageLoading] = useState(false)
  
  const [selectedContactMessageId, setSelectedContactMessageId] = useState('')
  const [deleteContactMessageId, setDeleteContactMessageId] = useState('')
  const [selectedContactMessage, setSelectedContactMessage] = useState<any>({})

  useEffect(() => {
    if (currentUser && currentUser._id && currentUser.isAdmin) {
      init()
    }
  }, [currentUser])

  useEffect(() => {
    setSelectedContactMessage(contactMessages.find((_it: any) => _it._id === selectedContactMessageId))
    if (selectedContactMessageId) {
      handleSetRead()
    }
  }, [selectedContactMessageId, contactMessages])
  
  const init = async () => {
    const _messages = await getAllContactMessages()
    setContactMessages(_messages)
    
    setContactMessageLoading(false)
  }

  const handleSetRead = async () => {
    const _res = await updateContactMessageById(selectedContactMessageId, { isRead: true })
    console.log('handleSetRed _res: ', _res)
    init()
  }
  
  const handleDeleteContactMessage = async () => {
    
    const _res = await deleteContactMessageById(deleteContactMessageId)
    if (_res && _res.success) {
      toast.success('Message Deleted Successfully!')
      setDeleteContactMessageId('')
      init()
    }
    
  }

  return (
    <div className='admin-contact-container'>

      <ReactModal
        isOpen={deleteContactMessageId.length > 0}
        onRequestClose={() => setDeleteContactMessageId('')}
      >
        <div className="profile-modal-body">
          <div className="close-modal" onClick={() => setDeleteContactMessageId('')}>
            <CloseIcon />
          </div>
          <h2>Contact Us Message Delete Confirmation</h2>
          <div className="modal-body-content">
            <p>Are you sure to delete this message?</p>
          </div>
        </div>
        
        <div className="delete-modal-footer">
          <button
            className='btn btn-yellow btn-xl'
            onClick={() => setDeleteContactMessageId('')}
          >
            Cancel
          </button>
          <button
            className='btn btn-yellow btn-xl'
            onClick={handleDeleteContactMessage}
          >
            Confirm
          </button>
        </div>

      </ReactModal>

      
      {!selectedContactMessageId ? (
        <>
          <div className="contact-header mb-2">
            <h1 className=''>Contact Us Messages</h1>
            <button
              className="btn btn-yellow"
              onClick={() => init()}
            >
              Refresh
            </button>
          </div>
          <div className="contact-list-container">
            {contactMessageLoading && <Loader />}
            {!contactMessageLoading && (
              <>
                <div className={`contact-item item-headers`}>
                  {/* <p className='contact-time'>{getFormattedDateTimeFromUTC(_it.createdAt)}</p>
                  <div className="contact-content" dangerouslySetInnerHTML={{__html: _it.content}}></div> */}
                  <div>S. No</div>
                  <div>Email</div>
                  <div>Date</div>
                  <div>Unread</div>
                  <div></div>
                </div>
                {contactMessages.map((_it: any) => (
                  <div className={`contact-item ${_it.isRead ? '' : 'unread'}`} key={_it._id}>
                    <div>{_it.nId}</div>
                    <div>{_it.email}</div>
                    <div>{getFormattedDateTimeFromUTC(_it.createdAt)}</div>
                    <div>{_it.isRead ? 'No' : 'Yes'}</div>
                    <div>
                      <button className="btn btn-yellow" onClick={() => setSelectedContactMessageId(_it._id)}>
                        View
                      </button>
                      
                      <button className="btn btn-yellow ml-2" onClick={() => setDeleteContactMessageId(_it._id)}>
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )} 
            {contactMessages.length === 0 && <p className='mt-3 mb-3 no-contactMessage'>No Contact Us Message to show.</p>}
          </div>
        </>
      ) : (
          <>
            <div className="contact-header">
              <h1 className='mb-2'>Contact Message</h1>
              <button className="btn btn-yellow" onClick={() => setSelectedContactMessageId('')}>
                Back
              </button>
            </div>
            {selectedContactMessage && selectedContactMessage._id && (
              <div className={`contact-item-details`}>
                <div className={`enter-contact-header`}>
                  <h4 className='desc-title'>{selectedContactMessage.email}</h4>
                  <h4 className="contact-id">{getFormattedDateTimeFromUTC(selectedContactMessage.createdAt)}</h4>
                </div>

                <div className="contact-con-container">
                  <div className="contact-content">
                    <p>{selectedContactMessage.content}</p>
                  </div>
                </div>
              </div>
            )}

            
          </>
      )}
      {/*  */}
    </div>
  )
}

export default ContactMessages