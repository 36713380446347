import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ImageGallery from 'react-image-gallery'
import { Editor } from '@tinymce/tinymce-react'

import { downloadInstaller, getGameById, getThumbById } from '../apis/game'
import { getFormattedDateTimeFromUTC, getOperatingSystem } from '../utils/helper'
import Loader from '../components/Loader'

import favIcon from '../assets/favorite.svg'
import followIcon from '../assets/wifi.svg'
import thumbIcon from '../assets/thumbs-up.svg'
import googlePlayBadge from '../assets/google-play-badge.png'
import "react-image-gallery/styles/css/image-gallery.css"
import '../styles/game-details.css'
import RootContext from '../context/RootContext'
import { createFeedback, getAvailableFeedbackId, getFeedbacksByFilter, uploadSingleAttachment } from '../apis/feedback'

const GameDetails = () => {
  const { gameId } = useParams()

  const {
    currentUser
  } = useContext(RootContext)

  const operatingSystem = getOperatingSystem()

  const [initLoading, setInitLoading] = useState(true)
  const [game, setGame] = useState<any>({})
  const [gameCovers, setGameCovers] = useState<any>([])
  const [downloadText, setDownloadText] = useState('')
  const [installerExt, setInstallerExt] = useState('')
  const [selectedTab, setSelectedTab] = useState('about')
  const [downloadPercent, setDownloadPercent] = useState(0)
  const [downloadStarted, setDownloadStarted] = useState(false)
  const [fileName, setFileName] = useState('')
  const [feedbacks, setFeedbacks] = useState<any>([])
  const [feedbackState, setFeedbackState] = useState('')
  const [coverLoading, setCoverLoading] = useState(true)

  const [availableFeedbackId, setAvailableFeedbackId] = useState<any>('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [attachments, setAttachments] = useState<any>([])

  const [replyOn, setReplyOn] = useState(false)
  const [headerText, setHeaderText] = useState('Feedback')

  useEffect(() => {
    if (currentUser && currentUser._id) {
      init()
    }
  }, [currentUser])

  useEffect(() => {
    if (game && game.installers) {
      const _targetInstaller = game?.installers.find((_it: any) => _it.platform === operatingSystem)
      // console.log('_targetInstaller: ', _targetInstaller)
      if (!_targetInstaller) {
        setDownloadText('N/A for ' + operatingSystem)
      } else {
        setDownloadText('Downlaod for ' + operatingSystem)
        setInstallerExt(_targetInstaller?.extension || '')
        setFileName((_targetInstaller?.fileName || 'download') + _targetInstaller?.extension)
      }

      handleGettingCovers()
    }

  }, [game])

  useEffect(() => {
    if (downloadPercent === 100) {
      setTimeout(() => {
        setDownloadStarted(false)
        setDownloadPercent(0)
      }, 3000)
    }
  }, [downloadPercent])

  const init = async () => {
    const _game = await getGameById(gameId || '')
    // console.log('_game: ', _game)
    setGame(_game)
    setInitLoading(false)

    const _fId = await getAvailableFeedbackId()
    if (_fId && !_fId.error) {
      setAvailableFeedbackId(_fId)
    }

    const _feedbacks = await getFeedbacksByFilter({ gameId })
    console.log('feedbacks: ', _feedbacks)
    setFeedbacks(_feedbacks)
  }

  const handleGettingCovers = async () => {
    let _gameCovers = []
    for (let _in = 0; _in < game.thumbExtensions.length; ++_in) {
      const _imgBlob: any = await getThumbById(game._id || '', _in)
      _gameCovers.push({
        original: _imgBlob,
        thumbnail: _imgBlob
      })
    }
    setGameCovers(_gameCovers)
    setCoverLoading(false)
  }

  const handleSubmitFeedback = async () => {
    if (!currentUser?._id) {
      return
    }
    const postBody: any = {
      gameId,
      userId: currentUser?._id,
      content: feedbackState,
      from: 'user',
      feedbackId: availableFeedbackId,
      name,
      email,
      subject,
    }

    const _res: any = await createFeedback(postBody)
    if (_res && _res.success) {
      toast.success(_res.message)
      handleUploadingAttachment(_res.feedBack._id)
      setFeedbacks([...feedbacks, _res.feedBack])
      setFeedbackState('')
      setName('')
      setEmail('')
      setSubject('')
      setAttachments([])
      const _fId = await getAvailableFeedbackId()
      if (_fId && !_fId.error) {
        setAvailableFeedbackId(_fId)
      }
    }

  }

  const handleUploadingAttachment = async (_id: any) => {
    for (const _file of attachments) {
      const _res = await uploadSingleAttachment(_file, _id)
    }


    toast.success('Attachments are uploaded successfully!')


  }

  return (
    <div className='root-container game-container'>
      {initLoading ? <Loader /> : (
        <>
          <div className="top-slider-details">
            <div className="top-slider">
              <div className={`top-slider-inner ${coverLoading ? 'cover-loading' : ''}`}>
                {/* {gameCover && <img src={gameCover} alt="Game Cover" />} */}
                {
                  coverLoading
                    ? <Loader />
                    : <ImageGallery items={gameCovers} autoPlay={false} />
                }

              </div>
            </div>
            <div className="top-details">
              <div className="primary-details">
                <h1 className='game-title'>{game.title}</h1>
                <p className='age-restriction'>Suitable For All Ages</p>
                <p className="company-name">Company Name</p>
              </div>
              <div className="icons-details">
                <div className="icon-details icon-fav">
                  <img src={favIcon} alt="Favorite" />
                  <span>Favorite</span>
                </div>
                <div className="icon-details icon-follow">
                  <img src={followIcon} alt="Follow" />
                  <span>Follow</span>
                </div>
                <div className="icon-details icon-thumb-up">
                  <img src={thumbIcon} alt="Thumb Up" />
                  <span>10k+</span>
                </div>
                <div className="icon-details icon-thumb-down">
                  <img src={thumbIcon} alt="Thumb Down" />
                  <span>0.3k</span>
                </div>
              </div>
              <div className="download-buttton">
                <div
                  className="download-button-inner"

                  onClick={async () => {
                    // console.log('path: ', game.installers)
                    if (!game.title || !installerExt || downloadStarted) {
                      return
                    }
                    setDownloadStarted(true)
                    await downloadInstaller(game._id, operatingSystem, toast, fileName, setDownloadPercent)
                    setDownloadStarted(false)
                  }}
                >
                  <strong>
                    {
                      // downloadPercent === 100 ? 'Downloaded 100%' :
                      // downloadStarted ? 'Downloading ' + downloadPercent + '%' : 
                      downloadText
                    }
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-buttons">
            <button
              className={`btn btn-gray btn-tab ${selectedTab === 'about' ? 'active' : ''}`}
              onClick={() => setSelectedTab('about')}
            >
              About
            </button>
            <button
              className={`btn btn-gray btn-tab ${selectedTab === 'store' ? 'active' : ''}`}
              onClick={() => setSelectedTab('store')}
            >
              Store
            </button>
            <button
              className={`btn btn-gray btn-tab ${selectedTab === 'feedback' ? 'active' : ''}`}
              onClick={() => setSelectedTab('feedback')}
            >
              Give Feedback
            </button>
          </div>
          <div className="tab-containers">
            {selectedTab === 'about' && (
              <div className="tab-about">
                <h4 className='desc-title'>Description</h4>
                <div className="desc-desc" dangerouslySetInnerHTML={{ __html: game.description }}></div>
              </div>
            )}
            {selectedTab === 'store' && (
              <div className="tab-store">
                <a
                  // href="https://play.google.com/apps/internaltest/4701743475608729457"
                  href="https://play.google.com/store/apps/details?id=com.FLUXIT.CherryBlossomHillsObstacle"
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={googlePlayBadge} alt="Google Play Badge" />
                </a>
              </div>
            )}
            {selectedTab === 'feedback' && (
              <div className="tab-feedback">
                {(feedbacks.length === 0 || replyOn) ? (
                  <>
                    <div className="enter-feedback-header reply">
                      <h4 className='desc-title'>{headerText}</h4>
                      <h4 className="feedback-id">#{availableFeedbackId}</h4>
                    </div>
                    <div className="enter-feedback-container">
                      <div className="name-email">
                        <div className="form-group">
                          <label htmlFor="">Name</label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">Email</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Subject</label>
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Attachments</label>
                        <input
                          type="file"
                          name="attachments"
                          id="attachments"
                          multiple
                          onChange={(e) => setAttachments(e.target.files)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Enter Feedback</label>
                        <Editor
                          apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                          init={{
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                          }}
                          // initialValue={editorState}
                          value={feedbackState}
                          onEditorChange={(_val) => setFeedbackState(_val)}
                        />
                      </div>
                    </div>
                  </>

                ) : (
                  <div className="feedback-list-container">
                    {feedbacks.map((_it: any, _in: number) => (
                      <div className={`feedback-item ${_it.from}`} key={_in}>
                        <div className={`enter-feedback-header header-${_in}`}>
                          <h4 className='desc-title'>{_it.subject}</h4>
                          <h4 className="feedback-id">#{_it.feedbackId}</h4>
                        </div>

                        <div className="feedback-con-container">
                          <h4>{_it.name}</h4>
                          <p className='feedback-time'>{getFormattedDateTimeFromUTC(_it.createdAt)}</p>
                          <div className="feedback-content" dangerouslySetInnerHTML={{ __html: _it.content }}></div>
                        </div>

                        {_it.reply && (
                          <div className="feedback-reply">
                            <p>Reply to: <strong>{_it.name} </strong> #{_it.feedbackId}</p>
                            <div className="reply-content" dangerouslySetInnerHTML={{ __html: _it.reply }}></div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                <div className="submit-feedback">
                  {(feedbacks.length === 0 || replyOn) ? (
                    <>
                      <button
                        className="btn btn-xl btn-yellow mr-2"
                        onClick={() => setReplyOn(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-xl btn-yellow"
                        onClick={handleSubmitFeedback}
                      >
                        Submit
                      </button>
                    </>
                  ) : (
                    <button
                      className="btn btn-xl btn-yellow"
                      onClick={() => setReplyOn(true)}
                    >
                      Reply
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="info-container">
            <div className="info-inner">
              <div className="info-item">
                <div className="label">
                  <span>Active</span>
                </div>
                <div className="value">
                  <span>2342</span>
                </div>
              </div>
              <div className="info-item">
                <div className="label">
                  <span>Favorite</span>
                </div>
                <div className="value">
                  <span>2398</span>
                </div>
              </div>
              <div className="info-item">
                <div className="label">
                  <span>Visits</span>
                </div>
                <div className="value">
                  <span>2342</span>
                </div>
              </div>
              <div className="info-item">
                <div className="label">
                  <span>Created</span>
                </div>
                <div className="value">
                  <span>{'23-07-2023'}</span>
                </div>
              </div>
              <div className="info-item">
                <div className="label">
                  <span>Updated</span>
                </div>
                <div className="value">
                  <span>{'23-08-2023'}</span>
                </div>
              </div>
              <div className="info-item">
                <div className="label">
                  <span>Server Size</span>
                </div>
                <div className="value">
                  <span>{38}</span>
                </div>
              </div>
              <div className="info-item">
                <div className="label">
                  <span>Genre</span>
                </div>
                <div className="value">
                  <span>All Genres</span>
                </div>
              </div>
            </div>

          </div>

          {/* <div className="recommended-games">
            <h2 className='reco-title'>Recommended Games</h2>
            <div className="recommended-items">
              {Array.from({ length: 5 }).map((_item, _in) => (
                <div className="reco-item" key={_in}>
                  <div className="reco-icon">
                    
                  </div>
                  <div className="reco-info">
                    <h4>Game Name</h4>
                    <span>Comapny Name</span>
                  </div>
                </div>
              ))}
              
            </div>
          </div> */}
        </>
      )}

    </div>
  )
}

export default GameDetails