import { useEffect, useState, useContext } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { createGame, getAllGameInfo, updateGame, uploadInstaller, uploadThumbs } from '../../apis/game'

import { getDateFromISODate, getFormattedDateYearTimeFromUTC } from '../../utils/helper'
import { toast } from 'react-toastify'
import RootContext from '../../context/RootContext'
import Loader from '../../components/Loader'


const Games = () => {
  const {
    currentUser
  } = useContext(RootContext)

  const [loading, setLoading] = useState(true)
  const [viewMode, setViewMode] = useState('list')
  const [selectedGameForEdit, setSelectedGameForEdit] = useState<any>({})
  const [installer, setInstaller] = useState<any>([])
  // const [uploadIndex, setUploadIndex] = useState(0)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [uploadedPercent, setUploadedPercent] = useState(0)

  const [gameTitle, setGameTitle] = useState('')
  const [gameDescState, setGameDescState] = useState('')

  const [thumbs, setThumbs] = useState<any>([])
  const [selectedPlatform, setSelectedPlatform] = useState('windows')

  const [games, setGames] = useState([])

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setLoading(true)
    const _games = await getAllGameInfo()
    setGames(_games)
    setLoading(false)
  }

  const handleAddEditGame = async () => {
    if (!gameTitle) {
      toast.warn('Game Title is requied')
      return
    }
    if (viewMode === 'edit' && selectedGameForEdit._id) {
      const updateBody = {
        title: gameTitle,
        description: gameDescState,
      }
      const _res = await updateGame(updateBody, selectedGameForEdit._id)

      if (thumbs) {
        const _res2 = await uploadThumbs(thumbs, selectedGameForEdit._id)
        console.log('thumb upload res: ', _res2)
      }
      toast.success(_res.message || 'Something went wrong! Please try again.')
    } else {
      const _res = await createGame({
        title: gameTitle,
        description: gameDescState,
        developer: currentUser.name
      })

      toast.success(_res.message || 'Something went wrong! Please try again.')
    }


    // console.log('create res: ', _res)

    setInstaller('')
    setThumbs([])
    setGameTitle('')
    setGameDescState('')
    await init()
    setViewMode('list')
  }

  return (
    <div className='admin-game-container'>
      {viewMode === 'list' && !selectedGameForEdit._id ? (
        <>
          <div className="game-table-header">
            <h1>Games</h1>
            <button
              className="btn btn-yellow"
              onClick={() => setViewMode('add')}
            >
              Add New Game
            </button>
            <button
              className="btn btn-yellow"
              onClick={() => init()}
            >
              Refresh
            </button>
          </div>

          <div className="game-table-container">
            {loading ? <Loader /> : (
              <div className="game-table">
                <div className="game-table-row">
                  <div className="table-cell title">
                    <strong>No</strong>
                  </div>
                  <div className="table-cell title">
                    <strong>ID</strong>
                  </div>
                  <div className="table-cell title">
                    <strong>Title</strong>
                  </div>
                  <div className="table-cell title">
                    <strong>Developer</strong>
                  </div>
                  <div className="table-cell title">
                    <strong>Release Date</strong>
                  </div>
                  <div className="table-cell title">
                    <strong>Update Date</strong>
                  </div>
                  <div className="table-cell title">
                    <strong>Win</strong>
                  </div>
                  <div className="table-cell title">
                    <strong>Mac</strong>
                  </div>
                  <div className="table-cell title">
                    <strong>Linux</strong>
                  </div>
                  <div className="table-cell title">
                    <strong>Total Download</strong>
                  </div>
                  <div className="table-cell title">
                    <strong>Action</strong>
                  </div>
                </div>


                {games.map((_it: any, _in) => (
                  <div className='game-table-row' key={_in}>
                    <div className="table-cell">
                      <span>{_in + 1}</span>
                    </div>
                    <div className="table-cell">
                      <span onClick={() => document.execCommand("copy")}>{_it._id}</span>
                    </div>
                    <div className="table-cell">
                      <span>{_it.title}</span>
                    </div>
                    <div className="table-cell">
                      <span>{_it.developer}</span>
                    </div>
                    <div className="table-cell">
                      <span>{getDateFromISODate(_it.releaseDate)}</span>
                    </div>
                    <div className="table-cell">
                      <span>{_it.updateDate ? getFormattedDateYearTimeFromUTC(_it.updateDate) : ''}</span>
                    </div>
                    <div className="table-cell">
                      <span>{_it?.installers?.find((_it2: any) => _it2.platform === 'windows') ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="table-cell">
                      <span>{_it?.installers?.find((_it2: any) => _it2.platform === 'mac') ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="table-cell">
                      <span>{_it?.installers?.find((_it2: any) => _it2.platform === 'linux') ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="table-cell">
                      <span>{_it?.downloadCount || 0}</span>
                    </div>
                    <div className="table-cell">
                      <button
                        className='btn btn-yellow'
                        onClick={() => {
                          setSelectedGameForEdit(_it)
                          setGameTitle(_it.title)

                          setGameDescState(_it.description || '')

                          setViewMode('edit')
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}

          </div>
        </>
      ) : (
        <>
          <div className="game-table-header">
            <h1>
              {viewMode === 'edit' ? 'Edit' : 'New Game'}
              <strong>{viewMode === 'edit' ? selectedGameForEdit.title : ''}</strong>
            </h1>
            <button
              className="btn btn-yellow"
              onClick={() => {
                setViewMode('list')
                setSelectedGameForEdit({})
                setUploadedPercent(0)
                init()
              }}
            >
              Back
            </button>
          </div>


          {viewMode === 'edit' && (
            <div className={`upload-container ${uploadedPercent > 0 ? 'uploading' : ''}`}>
              <div className="form-group select-platform">
                <label>Select Platform</label>
                <select
                  name="platform"
                  id="platform"
                  onChange={(e) => {
                    setSelectedPlatform(e.target.value)
                  }}
                >
                  <option
                    value='windows'
                    key='windows'
                    selected={selectedPlatform === 'windows'}
                  >
                    Windows
                  </option>
                  <option
                    value='mac'
                    key='mac'
                    selected={selectedPlatform === 'mac'}
                  >
                    Mac
                  </option>
                  <option
                    value='linux'
                    key='linux'
                    selected={selectedPlatform === 'linux'}
                  >
                    Linux
                  </option>
                </select>
              </div>
              <div className="form-group">
                <label>Select Installer</label>
                <input
                  type="file"
                  // accept=".exe,.msi"
                  // value={exeFile}
                  onChange={(event) => {
                    setInstaller(event.target.files?.[0])
                  }}
                />
              </div>



              {uploadedPercent > 0 && (
                <p className='upload-bar'>Uploaded: <strong>{uploadedPercent}%</strong></p>
              )}
              <div className="form-group submit">
                <button
                  className="btn btn-yellow btn-xl"
                  // disabled={uploadedPercent === 100 || installers.length === 0}
                  onClick={async () => {
                    setSubmitDisabled(true)
                    const _res = await uploadInstaller(
                      installer,
                      setUploadedPercent,
                      selectedGameForEdit._id,
                      selectedPlatform
                    )
                    console.log('res: ', _res)
                    if (_res) {
                      toast.success('Installers Uploaded Succesfuly')

                      setInstaller({})
                      setUploadedPercent(0)
                    }
                  }}
                >
                  {uploadedPercent === 100 ? 'Uploaded' : 'Upload'}
                </button>
              </div>
            </div>
          )}

          <div className="form-group">
            <label>Game Title</label>
            <input
              type="text"
              value={gameTitle}
              placeholder='Enter Game title'
              onChange={(e) => setGameTitle(e.target.value)}
            />
          </div>
          {viewMode === 'edit' && (
            <div className="form-group">
              <label>Select Thumbnail</label>
              <input
                type="file"
                accept='image/*'
                multiple
                placeholder='Enter Game Thumbnail'
                onChange={(e) => setThumbs(e.target.files || [])}
              />
            </div>
          )}
          <div className="form-group">
            <label>Game Description</label>
            <Editor
              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              init={{
                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
              }}
              // initialValue={editorState}
              value={gameDescState}
              onEditorChange={(_val) => setGameDescState(_val)}
            />
          </div>

          <div className="form-submit">
            <button
              className="btn btn-yellow btn-xl"
              onClick={handleAddEditGame}
            >
              Submit
            </button>
          </div>
        </>
      )}


    </div>
  )
}

export default Games