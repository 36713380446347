import { useContext, useEffect, useState } from 'react'

import { getPaypalClientID } from '../utils/helper'
import MarketplaceContext from '../context/MarketplaceContext'

import '../styles/buy-flux.css'
import PaypalPayment from '../components/PaypalPayment'
import RootContext from '../context/RootContext'
const BuyFlux = () => {
  const clientId = getPaypalClientID()
  const {
    cartItems,
    setCartItems,
    buyFluxAmount,
    setBuyFluxAmount
  }: any = useContext(MarketplaceContext)

  const [usdAmount, setUsdAmount] = useState(0.0)
  const [readyForCheckout, setReadyForCheckout] = useState(false)

  const {
    currentUser
  } = useContext(RootContext)
  

  useEffect(() => {
    const _total = cartItems.reduce((_sum: number, _itm: any) => _itm.price + _sum, 0)
    if (currentUser?.remainingFlux) {
      setBuyFluxAmount(Math.max(_total - currentUser.remainingFlux, 0))
    } else {
      setBuyFluxAmount(_total)
    }
  }, [currentUser])

  useEffect(() => {
    setUsdAmount(buyFluxAmount / 10.0)
  }, [buyFluxAmount])
  

  return (
    <div className='root-container buyflux-container'>
      <div className="pay-details">
        <label htmlFor="flux-amount">Enter Flux Amount</label>
        <input
          disabled={readyForCheckout}
          type="number"
          id='flux-amount'
          value={buyFluxAmount}
          onChange={(e: any) => setBuyFluxAmount(e.target.value)}
        />
        <h1 className='mt-4'>USD Amount ${usdAmount}</h1>
        <div className="mt-3 mb-3">
          <button
            disabled={usdAmount === 0}
            className="btn btn-xl btn-yellow active"
            onClick={() => setReadyForCheckout(true)}
          >
            Checkout
          </button>
        </div>
      </div>
      <div className="paypal-container">
        {readyForCheckout && (
          <PaypalPayment
            clientId={clientId}
            cartItems={cartItems.map((_it: any) => {
              _it.thumb = ''
              return _it
            })}
            setCartItems={setCartItems}
            payAmount={usdAmount}
            user={currentUser}
          />
        )}
        
      </div>
    </div>

  )
}

export default BuyFlux