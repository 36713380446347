import axios from '../config/axios'


export const updateOffer = async (postBody: any) => {
  const _url = '/offer'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.post(_url, postBody, _config)
    console.log('updateOffer res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('updateOffer error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


export const getOffer = async () => {
  const _url = '/offer'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    console.log('getOffer res.data: ', data)
    return data?.offer || {}
  } catch (_error: any) {
    console.log('getOffer error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


export const uploadOfferImage = async (file: any, _for: string) => {
  const _url = '/offer/' + _for + '-image'
  
  const _config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
  }
  try {
    
    const formData = new FormData();
    formData.append(_for, file)
    const { data } = await axios.post(_url, formData, _config)
    console.log(' uploadOfferImage res.data: ', data)
    
    return true //data && data.success
  } catch (_error: any) {
    console.log(' uploadOfferImage error: ', _error)
    const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
    return {
      error: true,
      message: msg
    }
  }
}

