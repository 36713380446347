import axios from '../config/axios'

export const getAllProducts = async () => {
  const _url = '/product'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    console.log('get-all-products res.data: ', data)
    return data?.data || []
  } catch (_error: any) {
    console.log('get-all-products error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getProductByIdAndToken = async (productId = '', token = '') => {
  const _url = '/product/' + productId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    console.log('getProductByIdAndToken res.data: ', data)
    return data?.data || []
  } catch (_error: any) {
    console.log('getProductByIdAndToken error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getThumbById = async (_productId: string) => {
  const _url = '/product/thumb/' + _productId
  const _blobConfig: any = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    responseType: 'blob',
  }

  try {
    const { data } = await axios.get(_url, _blobConfig)
    // console.log('get-thumb res.data: ', data.type)
    
    return data && data.type !== 'application/json' ? URL.createObjectURL(data) : ''
  } catch (_error: any) {
    console.log('get-thumb error: ', _error)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getProductsByFilter = async (_filterObj: any) => {
  const _url = '/product/filter'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.post(_url, _filterObj, _config)
    console.log('getProductsByFilter res.data: ', data)
    return data?.data || []
  } catch (_error: any) {
    console.log('getProductsByFilter error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}