import { reqConfig } from '../utils/helper'
import axios from '../config/axios'

export const isUserNameAvailabe = async (name: any) => {
  const _url = '/auth/is-available/' + name
  try {
    const { data } = await axios.get(_url, reqConfig)
    // console.log('isUserNameAvailabe res.data: ', data)
    return data && data.isAvailable
  } catch (_error: any) {
    console.log('isUserNameAvailabe error: ', _error)
    const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
    return {
      error: true,
      message: msg
    }
  }
}

export const inviteFriends = async (emails: string, userId: string) => {
  const _url = '/user/invite/' + userId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.post(_url, { emails }, _config)
    console.log('inviteFriends res.data: ', data)
    return data
  } catch (_error: any) {
    console.log('inviteFriends error: ', _error)
    const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
    return {
      error: true,
      message: msg
    }
  }
}


export const getUserNameSearch = async (userId: any, keyword: any) => {
  const _url = '/user/search/' + userId +  '?name=' + keyword
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    console.log('getUserNameSearch res.data: ', data)
    return data || []
  } catch (_error: any) {
    console.log('getUserNameSearch error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


export const getUserFromToken = async (_token: any) => {
  const _url = '/user/from-token'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + _token
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    console.log('getUserFromToken res.data: ', data)
    return data || []
  } catch (_error: any) {
    console.log('getUserFromToken error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getUsers = async () => {
  const _url = '/user'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    // console.log('getUsers res.data: ', data)
    return data || []
  } catch (_error: any) {
    console.log('getUsers error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const deleteUserById = async (userId: string) => {
  const _url = '/user/' + userId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.delete(_url, _config)
    // console.log('getUsers res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('deleteUserById error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const updateUserById = async (_body: any, userId: any) => {
  const _url = '/user/' + userId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.put(_url, _body, _config)
    console.log('updateUserById res.data: ', data)
    return data || []
  } catch (_error: any) {
    console.log('updateUserById error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}



export const uploadThumb = async (file: any, userId: any) => {
  const _url = '/user/upload-thumb/' + userId
  
  const _config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
  }
  try {
    
    const formData = new FormData();
    formData.append('thumb', file)
    const { data } = await axios.post(_url, formData, _config)
    console.log('profile uploadThumb res.data: ', data)
    
    return true //data && data.success
  } catch (_error: any) {
    console.log('profile uploadThumb error: ', _error)
    const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
    return {
      error: true,
      message: msg
    }
  }
}

export const updateFluxById = async (_body: any, userId: any, token = '') => {
  const _url = '/user/flux/' + userId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (token || localStorage.getItem('token'))
    }
  }
  try {
    const { data } = await axios.put(_url, _body, _config)
    console.log('updateUserById res.data: ', data)
    return data || []
  } catch (_error: any) {
    console.log('updateUserById error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


export const getUsersPaging = async ({
  currentPage,
  itemsPerPage,
  searchKey,
  sort
}: any) => {
  const _url = '/user/paging?currentPage=' + currentPage + '&itemsPerPage=' + itemsPerPage + '&searchKey=' + searchKey
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.post(_url, { sort }, _config)
    // console.log('getPagingCompletedOrders res.data: ', data)
    return data?.data || {}
  } catch (_error: any) {
    console.log('getPagingCompletedOrders error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getUsersCount = async () => {
  const _url = '/user/count'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    // console.log('getCompletedCount res.data: ', data)
    return data?.data || {}
  } catch (_error: any) {
    console.log('getCompletedCount error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}