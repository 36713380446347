import axios from '../config/axios'


export const createContactMessage = async (postBody: any) => {
  const _url = '/contact-message'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.post(_url, postBody, _config)
    // console.log('createFeecback res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('createFeecback error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getAllContactMessages = async () => {
  const _url = '/contact-message'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    return data?.data || []
  } catch (_error: any) {
    console.log('getContactMessagesByFilter error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}



export const deleteContactMessageById = async (contactMessageId: any) => {
  const _url = '/contact-message/' + contactMessageId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.delete(_url, _config)
    console.log('deleteContactMessageById res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('deleteContactMessageById error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const updateContactMessageById = async (contactMessageId: any, _postBody: any) => {
  const _url = '/contact-message/' + contactMessageId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.put(_url, _postBody, _config)
    console.log('updateContactMessageById res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('updateContactMessageById error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


