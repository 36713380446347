import axios from '../config/axios'


export const createLifePurchase = async (postBody: any, token: string) => {
  const _url = '/life'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }
  try {
    const { data } = await axios.post(_url, postBody, _config)
    console.log('createLifePurchase res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('createLifePurchase error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}