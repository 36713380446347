import { Oval } from "react-loader-spinner"

const Loader = ({
  color = '#008686',
  secondaryColor = '#fff'
}) => {
  return (
    <div className="loader-wrapper">
      <Oval
        height={80}
        width={80}
        color={color}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor={secondaryColor}
        strokeWidth={2}
        strokeWidthSecondary={2}

      />
    </div>
  )
}

export default Loader