import React, { useEffect, useState, createContext, useContext } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import ToastCont from '../components/ToastCont'
import '../styles/home-layout.css'
import '../styles/marketplace-layout.css'
import { marketplaceSidebarCategory, priceFilterItems, salesTypeFilterItems1, sortFilterItems, unavailableFilterItems } from '../utils/navdata'

import mainLogo from '../assets/dashboard/logo-home.png'
import Topbar from '../components/Topbar'
import NavFilters from '../components/NavFilters'
import MarketplaceContext from '../context/MarketplaceContext'
import RootContext from '../context/RootContext'
import { getCurrentUser } from '../apis/auth'


const MarketplaceLayout = () => {
  const navigate = useNavigate()
  const {
    currentUser,
    setCurrentUser
  } = useContext(RootContext)

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [activeCategory, setActiveCategory] = useState('all')
  const [activeSalesType, setActiveSalesType] = useState('all')
  const [sortBy, setSortBy] = useState('relevant')
  const [unavailableOption, setUnavailableOption] = useState('hide')
  const [cartItems, setCartItems] = useState<any[]>([])
  const [buyFluxAmount, setBuyFluxAmount] = useState(0)
  

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    const _user: any = await getCurrentUser()
    console.log('_user id from marketplace layout: ', _user._id)
    if (!_user || !_user._id) {
      navigate('/login')
    } else {
      setCurrentUser(_user)
    }
  }
  
  return (
    <MarketplaceContext.Provider
      value={{
        activeCategory,
        activeSalesType,
        sortBy,
        unavailableOption,
        cartItems,
        setCartItems,
        buyFluxAmount,
        setBuyFluxAmount
      }}
    >
      <ToastCont />
      <div className={`root-layout-container marketplace-layout ${sidebarOpen ? 'sidebar-open' : ''}`}>
        <div className="left-sidebar">
          <div className="main-logo">
            <img src={mainLogo} alt="Flux GameLand" />
          </div>
          <h1 className='main-title'>Marketplace</h1>
          <h2 className='nav-title'>Category</h2>
          <div className="side-navs">
            <ul>
              {marketplaceSidebarCategory.map((_dataItem, _in) => (
                <li
                  key={_in}
                  onClick={() => setActiveCategory(_dataItem.value)}
                  className={`${_dataItem.value === activeCategory ? 'active' : ''}`}
                >
                  {_dataItem.path ? (
                    <p>{_dataItem.title}</p>// to={_dataItem.path}
                  ) : (
                      <div className='category-0'>
                        <p>{_dataItem.title}</p>
                        {/* Sub items will be here */}
                      </div>
                  )}
                  
                </li>
              ))}
            </ul>
          </div>

          <h2 className="nav-title">Filters</h2>
          <NavFilters
            filterTitle='Sales Type'
            activeType={activeSalesType}
            setActiveType={setActiveSalesType}
            filterItems={salesTypeFilterItems1}
          />
          {/* <NavFilters
            filterTitle='Price'
            filterItems={priceFilterItems}
          /> */}
          <NavFilters
            filterTitle='Sort By'
            activeType={sortBy}
            setActiveType={setSortBy}
            filterItems={sortFilterItems}
          />
          <NavFilters
            filterTitle='Unavailable Item'
            activeType={unavailableOption}
            setActiveType={setUnavailableOption}
            filterItems={unavailableFilterItems}
          />
          
        </div>
        
        <Topbar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <Outlet />
        
      </div>
    </MarketplaceContext.Provider>
  )
}

export default MarketplaceLayout