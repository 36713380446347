import '../styles/official-store.css'
import googlePlayBadge from '../assets/google-play-badge.png'

const OfficialStore = () => {
  return (
    <div className='official-store'>
      <div className="store-content">
        <h1>Download</h1>
        <h3>Our Mobile App</h3>
        <p className="store-desc">
          Welcome to Cherry blossoms Hill. This game has 5 levels and two character Raven and Alex. You have to choose any one character for play. You can go to the next level after finishing one by one level.
        </p>
        <img src="" alt="" />
        <a
          href="https://play.google.com/store/apps/details?id=com.FLUXIT.CherryBlossomHillsObstacle"
          target='_blank'
          rel='noreferrer'
        >
          <img src={googlePlayBadge} alt="Google Play Badge" />
        </a>
      </div>
    </div>
  )
}

export default OfficialStore