import ReactPaginate from 'react-paginate'

const Pagination = ({
  totalItemCount,
  handlePageClick,
  pageCount,
}: any) => {

  

  
  return (
    <div className='mt-3'>
      {totalItemCount > 0 && (
        <ReactPaginate
          breakLabel={"..."} 
          nextLabel="Next"
          onPageActive={(e) => console.log('onPageActive: ', e.selected)}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Previous"
          marginPagesDisplayed={3}
          //renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active" 
        />
      )}
    </div>
  )
}

export default Pagination