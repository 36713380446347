import React, { useEffect, useState, useContext } from 'react'
import { Link, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ToastCont from '../components/ToastCont'
import '../styles/home-layout.css'
import { sidebarDashboardData, sidebarAdminData } from '../utils/navdata'

import mainLogo from '../assets/dashboard/logo-home.png'
import defaultFemale from '../assets/dashboard/default-female.png'
import defaultMale from '../assets/dashboard/default-male.png'
import Topbar from '../components/Topbar'
import { getCurrentUser } from '../apis/auth'
import RootContext from '../context/RootContext'
import AdminContext from '../context/AdminContext'
import { getBaseURL } from '../utils/helper'

const HomeLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const adminView = searchParams.get('tab')

  const {
    currentUser,
    setCurrentUser
  } = useContext(RootContext)

  const [sidebarOpen, setSidebarOpen] = useState(false)

  // const [adminView, setAdminView] = useState('Games')

  useEffect(() => {
    // console.log('homeloayout location.pathname: ', location.pathname)
    init()
  }, [])

  
  useEffect(() => {
    if (!adminView) {
      setSearchParams({ tab: 'Games' })
    }
  }, [adminView])

  const init = async () => {
    const _user: any = await getCurrentUser()
    if (!_user || !_user._id) {
      navigate('/login')
    } else {
      // console.log('us')
      setCurrentUser(_user)
    }
  }

  const getProfileThumbSrc = () => {
    if (currentUser?.thumbExtension) {
      return getBaseURL() + '/profile-thumb/thumb-' + currentUser._id + currentUser.thumbExtension
    }
    return currentUser?.gender === 'Male' ? defaultMale : defaultFemale
  }
  
  return (
    <AdminContext.Provider
      value={{
        adminView
      }}
    >
      <ToastCont />
      <div className={`root-layout-container ${sidebarOpen ? 'sidebar-open' : ''}`}>
        <div className="left-sidebar">
          <div className="main-logo">
            <img src={mainLogo} alt="Flux GameLand" />
          </div>
          <div
            className="profile-logo"
            onClick={() => navigate('/profile')}
          >
            <img src={getProfileThumbSrc()} alt="Profile Icon" />
          </div>
          <div
            className="profile-name"
            onClick={() => navigate('/profile')}
          >
            <h2 className={`len-${currentUser?.name?.length > 30 ? 'xl' : currentUser?.name?.length}`}>{currentUser?.name}</h2>
          </div>
          <div className="side-navs">
            <ul>
              {
                location.pathname === '/dashboard/admin'
                  ? sidebarAdminData.map((_dataItem, _in) => (
                    <li
                      key={_in}
                      onClick={() => {
                        // searchParams.set('tab', )
                        setSearchParams({ tab: _dataItem.title })
                      }}
                    >
                      <p className={_dataItem.title === adminView ? 'admin-active': ''}>{_dataItem.title}</p>
                    </li>
                  ))
                  : sidebarDashboardData.map((_dataItem, _in) => (
                    <li key={_in}>
                      <Link to={_dataItem.path}>{_dataItem.title}</Link>
                    </li>
                  ))
              }
            </ul>
          </div>
        </div>
        
        <Topbar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <Outlet />
        
      </div>
    </AdminContext.Provider>
  )
}

export default HomeLayout