import React, { useContext, useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import searchIcon from '../assets/marketplace/search.png'
import fluxIcon from '../assets/flux.png'
import { marketItemsCategory, marketItems } from '../utils/demodata'

import '../styles/marketplace.css'
import CartIcon from '../components/icons/CartIcon'
import { getAllProducts, getProductsByFilter, getThumbById } from '../apis/product'
import Loader from '../components/Loader'
import { errorResponseHandler } from '../utils/helper'
import MarketplaceContext from '../context/MarketplaceContext'
import iconTimes from '../assets/icon-times.svg'
import RootContext from '../context/RootContext'


let searchTimeout: any = ''

const Marketplace = () => {
  const navigate = useNavigate()
  const cartContainerRef = useRef<HTMLDivElement>(null)
  const cartOpenBtnRef = useRef<HTMLDivElement>(null)

  const {
    activeCategory,
    activeSalesType,
    sortBy,
    unavailableOption,
    cartItems,
    setCartItems
  } = useContext(MarketplaceContext)

  const {
    currentUser
  } = useContext(RootContext)

  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('Initial')
  const [isCartOpen, setIsCartOpen] = useState(false)

  const [filteredItems, setFilteredItems] = useState<any>([])
  const [filteredItems2, setFilteredItems2] = useState<any>([])
  
  const [thumbs, setThumbs] = useState<any>([])

  const [searchKey, setSearchKey] = useState('')

  useEffect(() => {
    initFetch()
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        cartContainerRef.current &&
        !cartContainerRef.current.contains(event.target) && 
        !cartOpenBtnRef.current?.contains(event.target)
      ) {
        setIsCartOpen(false)
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cartContainerRef])

  useEffect(() => {
    console.log('currentuser 2: ', currentUser)
    const _tempItems = filteredItems.map((_it: any) => {
      const _found = currentUser?.purchasedProductIds?.find((_it2: any) => _it2 === _it._id)
      return {
        ..._it,
        alreadyPurchased: _found ? true : false
      }
    })
    setFilteredItems(_tempItems)
    setFilteredItems2(_tempItems)
  }, [currentUser])

  useEffect(() => {
    const _filter: any = unavailableOption === 'hide' ? {
      isAvailable: true,
    } : {}
    if (activeCategory !== 'all') {
      _filter.category = activeCategory
    }
    if (activeSalesType !== 'all') {
      _filter.salesType = activeSalesType
    }

    const _sortObj: any = {}
    if (sortBy !== 'relevant') {
      _sortObj.price = sortBy
    }

    const _filterObj = { filter: _filter, sort: _sortObj }
    console.log('_filterObj: ', _filterObj)
    handleApplyFilter(_filterObj)
  }, [
    activeCategory,
    activeSalesType,
    sortBy,
    unavailableOption
  ])

  const initFetch = async () => {
    const _preProducts = await getAllProducts()
    await handleFetchingThumbs(_preProducts)
  }

  const handleApplyFilter = async (_filterObj: any) => {
    setIsLoading(true)
    const _preProducts = await getProductsByFilter(_filterObj)
    if (_preProducts.loginExpired) {
      return
    }
    await handleFetchingThumbs(_preProducts)
  }

  const handleFetchingThumbs = async (_preProducts: any) => {
    const _products = errorResponseHandler(_preProducts, toast, navigate)
    console.log('currentUser: ', currentUser)
    if (_products.loginExpired) {
      return
    }
    const _tempProducts = _products?.map((_it: any) => {
      const _found = currentUser?.purchasedProductIds?.find((_it2: any) => _it2 === _it._id)
      return {
        ..._it,
        alreadyPurchased: _found ? true : false
      }
    }) || []
    setFilteredItems(_tempProducts)
    setFilteredItems2(_tempProducts)
    const _tempThumbs = Array.from({length: _products.length})
    for (let _in = 0; _in < _products.length; ++_in) {
      const _imgBlob: any = await getThumbById(_products[_in]._id)
      _tempThumbs[_in] = _imgBlob
    }
    setIsLoading(false)
    setThumbs(_tempThumbs)
  }

  const handleCartAddRemove = (_targetItem: any) => {
    const _alreadyAdded = cartItems.find((_itm: any) => _itm._id === _targetItem._id)
    if (_alreadyAdded) {
      setCartItems(cartItems.filter((_itm: any) => _itm._id !== _targetItem._id))
    } else {
      setCartItems([
        ...cartItems,
        _targetItem
      ])
    }
  }

  const getAddRemoveCartText = (_targetItem: any) => {
    const _alreadyAdded = cartItems.find((_itm: any) => _itm._id === _targetItem._id)
    if (_targetItem.alreadyPurchased) {
      return 'Already Purchased'
    } else if (_alreadyAdded) {
      return 'Remove from cart'
    }

    
    return 'Add to cart'
  }

  const getCartTotal = () => cartItems.reduce((_sum: number, _itm: any) => _itm.price + _sum, 0)

  const handleSearchProduct = async (event: any) => {
    const _value = event.target.value
    setSearchKey(_value)
    // console.log('search key: ', _value)
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    searchTimeout = setTimeout(async () => {
      const _tempItems = filteredItems.filter((_it: any) => _it.name.toLowerCase().includes(_value.toLowerCase()))
      console.log('searched items: ', _tempItems)
      setFilteredItems2(_tempItems)
    }, 800)
  }

  return (
    <div className='root-container marketplace-container'>
      <div className="search-bar">
        <div className="search-box">
          <div className="search-box-inner">
            <img src={searchIcon} alt="Search Icon" />
            <input
              type="text"
              placeholder='Search'
              value={searchKey}
              onChange={handleSearchProduct}
            />
          </div>
          
        </div>
        <div className="filter-dropdown">
          <button className='btn btn-round btn-yellow'>
            All Items
          </button>
        </div>
        <div className="btn-buy-flux">
          <button className="btn btn-round btn-yellow active">
            <Link to={'/marketplace/buy'}>Buy Flux</Link>
          </button>
        </div>
        <div className="btn-direct-buy">
          <button className="btn btn-round btn-yellow active">
            <Link to='/marketplace/direct-buy'>Direct Buy</Link>
          </button>
        </div>
        <div
          ref={cartOpenBtnRef}
          className="btn-cart"
          onClick={() => {
            setIsCartOpen(!isCartOpen)
          }}
        >
          <CartIcon />
          <div className="cart-count">
            <span>{cartItems.length}</span>
          </div>
          {isCartOpen && (
            <div ref={cartContainerRef} className="cart-container">
              <div className="cart-header">
                <h2>Cart</h2>
                <div
                  className="cart-close"
                  onClick={() => setIsCartOpen(false)}
                >
                  <img src={iconTimes} alt="cross" />
                </div>
              </div>
              <div className="cart-body">
                {cartItems.map((_itm: any, _in: number) => (
                  <div className="cart-item" key={_in}>
                    <div className="cart-item-logo">
                      {_itm.thumb && (
                        <img src={_itm.thumb} alt={_itm.name} />
                      )}
                    </div>
                    <div className="cart-item-title">
                      <strong>{_itm.name}</strong>
                    </div>
                    <div className="cart-item-flux">
                      <img src={fluxIcon} alt="Flux" />
                      <strong>{_itm.price}</strong>
                    </div>
                  </div>
                ))}
                {cartItems.length ? (
                  <div className="cart-item">
                    <div></div>
                    <div className="cart-item-title total-title">
                      <strong>Grand Total</strong>
                    </div>
                    <div className="cart-item-flux">
                      <img src={fluxIcon} alt="Flux" />
                      <strong>{getCartTotal()}</strong>
                    </div>
                  </div>
                ) : (
                    <div className="cart-empty">
                      <p>No Item Selected</p>
                    </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="all-items-container">
        <h2 className='all-items-title mb-2 mt-2'>All Items</h2>
        <div className="all-items-tab mb-2">
          {marketItemsCategory.map((_cate, _in) => (
            <button
              key={_in}
              className={`btn btn-round btn-yellow ${_cate === activeTab || (activeTab === 'Initial' && _in === 0) ? 'active' : ''}`}
              onClick={() => {
                setActiveTab(_cate)
                // if (_cate === 'Initial') {
                //   setFilteredItems(marketItems)
                // } else {
                //   setFilteredItems(marketItems.filter((_it, _in) => _it.category === _cate))
                // }
                
              }}
            >
              {_cate}
            </button>
          ))}
        </div>

        {isLoading ? <Loader /> : (
          <div className="all-items">
            {filteredItems2.map((_itm: any, _in: number) => (
              <div className="market-item" key={_in}>
                <div
                  className="market-item-icon"
                  onClick={() => navigate('/product/' + _itm._id)}
                >
                  {thumbs?.[_in] && <img src={thumbs[_in]} alt="" />}
                </div>
                <div className="market-item-desc">
                  <div
                    className="item-title"
                    onClick={() => navigate('/product/' + _itm._id)}
                  >
                    <p><strong>{_itm.name}</strong></p>
                  </div>
                  <div className="item-flux">
                    <img src={fluxIcon} alt="Flux Icon" />
                    <span>{_itm.price}</span>
                  </div>
                  <div
                    className={`btn-add-cart ${_itm.alreadyPurchased ? 'already-purchased' : ''}`}
                    onClick={() => {
                      if (thumbs?.[_in]) {
                        _itm.thumb = thumbs?.[_in]
                      }
                      if (_itm.alreadyPurchased) {

                      } else {
                        handleCartAddRemove(_itm)
                      }
                      
                    }}
                  >
                    <p>{getAddRemoveCartText(_itm)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        
        {!filteredItems.length && (
          <div className="no-items">
            <p className="text-center mt-4">No items to show</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Marketplace