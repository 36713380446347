import React from 'react'

import personSRC from '../assets/gift-card/person.png'
import comingSoon from '../assets/gift-card/coming-soon.png'
import '../styles/coming-soon.css'

const ComingSoon = () => {
  return (
    <div className='coming-soon'>
      {/* <h1>Coming soon</h1> */}
      <div className="person">
        <img src={personSRC} alt="Person" />
      </div>
      <div className="coming-soon-text">
        <img src={comingSoon} alt="Coming Soon" />
      </div>
    </div>
  )
}

export default ComingSoon