import React from 'react'
import '../styles/nav-filters.css'

const NavFilters = ({
  filterTitle,
  filterItems,
  activeType,
  setActiveType
}: any) => {
  return (
    <div className="filter-container">
      <h3 className="nav-filter-title">
        {filterTitle}
      </h3>
      <div className="filter">
        {filterItems.map((_filterItem: any, _in: number) => (
          <div
            key={_in}
            className={`filter-item ${_filterItem.value === activeType ? 'active' : ''}`}
            onClick={() => {
              setActiveType(_filterItem.value)
            }}
          >
            <div className="custom-radio">
              <div className="custom-radio-inner"></div>
            </div>
            <div className="custom-radio-desc">
              {_filterItem.label}
            </div>
          </div>
        ))}
        
      </div>
    </div>

  )
}

export default NavFilters