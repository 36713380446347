import ToastCont from '../components/ToastCont'
import '../styles/layout.css'
import { Outlet } from 'react-router-dom'

const BasicLayout = () => {
  
  return (
    <>
      <ToastCont />
      <div className='layout-container'>
        <Outlet />
      </div>
    </>
  )
}

export default BasicLayout