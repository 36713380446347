import { getBaseURL, reqConfig } from '../utils/helper'
import axios from '../config/axios'

export const createOrGetSingleChat = async (currentUserId: any, friendId: any) => {
  const _url = '/chat/' + currentUserId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.post(_url, { friendId }, _config)
    // console.log('createOrGetSingleChat res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('createOrGetSingleChat error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}
export const toggleFriends = async (chatID: any) => {
  const _url = '/chat/friends/' + chatID
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  console.log("Token value",localStorage.getItem('token'))
  try {
    const { data } = await axios.put(_url,{},_config)
    // console.log('toggleFriends res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('toggleFriends error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}
export const getFriends = async (userID: any) => {
  const _url = '/chat/friends/' + userID
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  
  try {
    const { data } = await axios.get(_url,_config)
    // console.log('getFriends res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('getFriends error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getAllChatOfCurrentUser = async (currentUserId: any) => {
  const _url = '/chat/' + currentUserId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    // console.log('getAllChatOfCurrentUser res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('getAllChatOfCurrentUser error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}
