import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import lifeProgressImg from '../assets/life-progress.png'
import lightingYel from '../assets/lighting-life-buy.png'
import '../styles/game-buy-life.css'
import { toast } from 'react-toastify'
import { getUserFromToken } from '../apis/user'
import { createLifePurchase } from '../apis/life'
import fluxIcon from '../assets/flux.svg'
import { useSocket } from '../context/SocketContext'
// import { sendMessageToUnity } from '../utils/unity-connect'

const GameBuyLife = () => {
  const navigate = useNavigate()
  const { socket } = useSocket()

  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('token')

  const [lifePrice, setLifePrice] = useState(59.9)
  const [currentUser, setCurrentUser] = useState<any>({})
  const [exitMessage, setExitMessage] = useState('')

  useEffect(() => {
    // console.log('token: ', token)
    handleGettingCurrentUser()
  }, [token])


  const handleGettingCurrentUser = async () => {
    const __user = await getUserFromToken(token)
    console.log('__user: ', __user)
    if (__user.success === false) {
      toast.warn('Invalid Token!')
    } else {
      setCurrentUser(__user)
    }
  }

  const handleDirectBuy = async () => {
    const _body = {
      fluxAmount: lifePrice,
      lifeAmount: 1,
    }
    const _resData = await createLifePurchase(_body, token || '')
    console.log(_resData)
    if (_resData && _resData.success) {
      
      if (socket) {
        const _socketMsg = {
          name: 'life',
          purchaseFrom: 'current-balance',
          message: 'Done',
          userId: currentUser._id,
        }
        socket.emit('buy-from-game', _socketMsg)
      }
      
      navigate('/game/buy-close?key=life-success')
    } else {
      // toast.error('Something went wrong. Please try again!')
    }
  }

  return (
    <div className='root-container buy-life-container'>
      
      <div className="life-container-inner">
        <h1>BUY LIFE</h1>
        <img src={lifeProgressImg} alt="life-progress" />
        <h4>Would you like to buy "One Life"?</h4>
        <div className="direct-buy">
          <div className="remaining-flux">
            <div>
              Current Balance:
            </div>
            <strong className='ml-1 flux-strong-amount'>
              <img src={fluxIcon} alt="Flux Icon" />
              <span>{parseFloat(currentUser.remainingFlux).toFixed(2) || 0}</span>
            </strong>
          </div>
          {parseFloat(currentUser.remainingFlux || 0) >= lifePrice && (
            <div className="mt-3 mb-3 action-btns remaining-flux">
              <button
                className="btn btn-xl btn-yellow active"
                onClick={handleDirectBuy}
              >
                Purchase From Remaining Flux
              </button>
            </div>
          )}
        </div>
        <div className="life-btns">
          <button
            className="btn btn-life"
            onClick={() => {
              if (socket) {
                const _socketMsg = {
                  name: 'life',
                  message: 'Cancelled',
                  userId: currentUser._id,
                }
                socket.emit('buy-from-game', _socketMsg)
              }

              navigate('/game/buy-close?key=close')
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-life btn-icon"
            onClick={() => {
              navigate('/game/buy-flux?from=buy-life&fluxAmount=' + lifePrice + '&token=' + token)
            }}
          >
            <img src={lightingYel} alt={'Lighting Icon'} className='' />
            <span>{lifePrice}</span>
          </button>
        </div>
      </div>
      

      
    </div>

  )
}

export default GameBuyLife