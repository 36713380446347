import { useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "./layout/AuthLayout";
import HomeLayout from "./layout/HomeLayout";
import MarketplaceLayout from "./layout/MarketplaceLayout";
import Dashboard from "./pages/Dashboard";
import Marketplace from "./pages/Marketplace";
import Profile from "./pages/Profile";
import BuyFlux from "./pages/BuyFlux";
import DirectBuy from "./pages/DirectBuy";
import RootContext from "./context/RootContext";
import GameDetails from "./pages/GameDetails";
import OnlyTopbarLayout from "./layout/OnlyTopbarLayout";
import Referral from "./pages/Referral";
import GamePaypalPayment from "./pages/GamePaypalPayment";
import GameBuyLayout from "./layout/GameBuyLayout";
import GameBuyLife from "./pages/GameBuyLve";
import { SocketProvider } from "./context/SocketContext";
import GameBuyClose from "./pages/GameBuyClose";
import ProductDetails from "./pages/ProductDetails";
import BasicLayout from "./layout/BasicLayout";
import TermsAndServices from "./pages/TermsAndServices";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import GiftCards from "./pages/GiftCards";
import OfficialStore from "./pages/OfficialStore";
import ContactUs from "./pages/ContactUs";
import Feedbacks from "./pages/Feedbacks";


const router = createBrowserRouter([
  {
    path: "/",
    Component: AuthLayout,
    children: [
      { path: "/", Component: Home },
      { path: "/login", Component: Login },
      { path: "/signup", Component: Signup },
    ]
  },
  {
    path: "/game",
    Component: GameBuyLayout,
    children: [
      { path: '/game/buy-flux', Component: GamePaypalPayment },
      { path: '/game/buy-life', Component: GameBuyLife },
      { path: '/game/buy-close', Component: GameBuyClose }
    ]
  },
  {
    path: '/dashboard',
    Component: HomeLayout,
    children: [
      { path: "/dashboard/game/:gameId", Component: GameDetails },
      { path: '/dashboard/profile', Component: Profile },
      { path: '/dashboard/official-store', Component: OfficialStore },
      { path: "/dashboard/admin", Component: Admin },
      { path: '/dashboard', Component: Dashboard },
    ]
  },
  {
    path: '/official-store',
    Component: HomeLayout,
    children: [
      { path: '/official-store', Component: OfficialStore },
    ]
  },
  {
    path: '/feedbacks',
    Component: HomeLayout,
    children: [
      { path: '/feedbacks', Component: Feedbacks },
    ]
  },
  {
    path: '/marketplace',
    Component: MarketplaceLayout,
    children: [
      { path: '/marketplace', Component: Marketplace },
      { path: '/marketplace/buy', Component: BuyFlux },
      { path: '/marketplace/direct-buy', Component: DirectBuy },
    ]
  },
  {
    path: '/invite',
    Component: OnlyTopbarLayout,
    children: [
      { path: '/invite', Component: Referral },
    ]
  },
  {
    path: '/profile',
    Component: OnlyTopbarLayout,
    children: [
      { path: '/profile', Component: Profile },
    ]
  },
  {
    path: '/gift-cards',
    Component: OnlyTopbarLayout,
    children: [
      { path: '/gift-cards', Component: GiftCards },
    ]
  },
  {
    path: '/avatar',
    Component: OnlyTopbarLayout,
    children: [
      { path: '/avatar', Component: GiftCards },
    ]
  },
  {
    path: '/product',
    Component: OnlyTopbarLayout,
    children: [
      { path: '/product/:productId', Component: ProductDetails },
    ]
  },
  {
    path: '/terms-and-condition',
    Component: BasicLayout,
    children: [
      { path: '/terms-and-condition', Component: TermsAndServices },
    ]
  },
  {
    path: '/privacy-policy',
    Component: BasicLayout,
    children: [
      { path: '/privacy-policy', Component: PrivacyPolicy },
    ]
  },
  {
    path: '/contact-us',
    Component: BasicLayout,
    children: [
      { path: '/contact-us', Component: ContactUs },
    ]
  },
  {
    path: '/feedback',
    Component: BasicLayout,
    children: [
      { path: '/feedback', Component: ContactUs },
    ]
  },
  { path: "*", Component: Root },
]);

export default function App() {
  

  const [currentUser, setCurrentUser] = useState<any>({})
  const [totalFriends, setTotalFriends] = useState(0)
  const [isChatOpen,setIsChatOpen]=useState<boolean>(false)
  const [selectedFriendIdforChatOpen,setSelectedFriendIdforChatOpen]=useState("")
  // const [isFriendChatOpen, setIsFriendChatOpen] = useState(false);
  const [messages, setMessages] = useState<any[]>([])
  const [selectedChat, setSelectedChat] = useState<any>({})

  return (
    <RootContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        totalFriends, 
        setTotalFriends,
        isChatOpen,
        setIsChatOpen,
        selectedFriendIdforChatOpen,
        setSelectedFriendIdforChatOpen,
        selectedChat, 
        setSelectedChat,
        messages, 
        setMessages
        
      }}
    >
      <SocketProvider>
        <RouterProvider router={router} />
      </SocketProvider>
    </RootContext.Provider>
  );
}

function Root() {

  return (
    <Routes>
    </Routes>
  );
}