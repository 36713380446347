import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/home.css'

const Home = () => {
  return (
    <div className='home-wrapper'>
      <div className="welcome-container text-center">
        <h1>Welcome</h1>
        <p>to</p>
        <h1 className='uppercase fs-3'>Flux Gameland</h1>
        <div className='mt-4 mb-1'>
          <Link to='/signup'>
            <button className='uppercase btn btn-white'>Sign Up</button>
          </Link>
        </div>
        <p>or</p>
        <div className='mt-1 mb-2'>
          <Link to='/login'>
            <button className='uppercase btn btn-white'>Log In</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Home