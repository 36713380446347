import { useEffect, useState } from 'react'
import { getCompletedCount, getPagingCompletedOrders } from '../../apis/order'
import Loader from '../../components/Loader'
import { getFormattedDateYearTimeFromUTC } from '../../utils/helper'

import '../../styles/admin-payments.css'
import Pagination from '../../components/Pagination'
import ReactSelect, { SingleValue, StylesConfig } from 'react-select'

const itemsPerPage = 20

const PaymentsAdmin = () => {

  const [paymentsLoading, setPaymentsLoading] = useState(true)
  const [payments, setPayments] = useState<any>([])
  const [totalPaymentCount, setTotalPaymentCount] = useState(0)
  const [totalPayment, setTotalPayment] = useState(0)
  const [selectedTransType, setSelectedTransType] = useState<SingleValue<{ label: string; value: string; }>>({ label: 'LIVE', value: 'LIVE' })
  const [transOptions] = useState([{ label: 'LIVE', value: 'LIVE' }, { label: 'TEST', value: 'TEST' }])

  useEffect(() => {
    initialize()
    handleFetch()
  }, [])
  

  const initialize = async () => {
    const _data = await getCompletedCount(selectedTransType?.value || 'LIVE')
    // console.log('payments count: ', _data)
    setTotalPayment(_data.totalPayment)
  }

  useEffect(() => {
    initialize()
    handleFetch()
  }, [selectedTransType])

  // pagination start
  const [currentPage, setCurrentPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)



  useEffect(() => {
    setPageCount(Math.ceil(totalItemCount / itemsPerPage))
  }, [itemsPerPage, totalItemCount])


  const handleFetch = async (page = 0) => {
    setPaymentsLoading(true)
    const pageBody = {
      currentPage: page,
      itemsPerPage,
      transactionType: selectedTransType?.value
    }
    console.log('pageBody: ', pageBody)
    const _data = await getPagingCompletedOrders(pageBody)

    console.log('pagination data: ', _data?.items?.length)
    if (_data?.items) {
      setPayments(_data.items?.map((_it: any, _in: number) => {
        // console.log('itemsPerPage, currentPage, _in + 1: ', itemsPerPage, page, _in + 1)
        _it.itemNo = itemsPerPage * page + (_in + 1)
        return _it
      }) || [])
      setTotalItemCount(_data?.totalCount || 0)
      setTotalPaymentCount(_data?.totalCount || 0)
      
    } else {
      setPayments( [])
      setTotalItemCount( 0)
      setTotalPaymentCount(0)
    }
    setCurrentPage(pageBody.currentPage)
    setPaymentsLoading(false)
  }

  const handlePageClick = async (e: any) => {
    await handleFetch(e.selected)
  }

  // pagination end

  
  const customStyles: StylesConfig = {
    control: (provided) => ({
      ...provided,
      fontSize: '16px', // Increase font size for the selected value
      color: '#000'
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: '16px', // Increase font size for the dropdown options
      color: '#000'
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '16px', // Font size for each option
      color: '#000',
      backgroundColor: '#f0f0f0' 
    }),
  };

  return (
    <div className='admin-payments-container'>
      <div className="payment-header">
        <h1>Payments</h1>
        <h1>Total: <strong>${totalPayment}</strong></h1>
        <button
          className="btn btn-yellow"
          onClick={() => {
            handleFetch(currentPage)
            initialize()
          }}
        >
          Refresh
        </button>
      </div>
      <div className="payment-filter">
        <label htmlFor="">Transaction Type</label>
        <ReactSelect
          value={selectedTransType}
          options={transOptions}
          onChange={(_s) => setSelectedTransType(_s as SingleValue<{ label: string; value: string; }>)}
          styles={customStyles}
        />
      </div>
      <div className="payment-list">
        {totalPaymentCount > 0 && (
          <div className={`payment-item item-headers`}>
            <div>S. No</div>
            <div>User</div>
            <div>Item/Flux Count</div>
            <div>Price Paid($)</div>
            <div>Gateway</div>
            <div>Date</div>
          </div>
        )}
        {paymentsLoading && <Loader />}

        {!paymentsLoading && payments.length > 0 && (
          <>
            {payments.map((_it: any) => (
              <div className={`payment-item`} key={_it._id}>
                <div>{_it.readableId}</div>
                <div>{_it.userId?.name || ''}</div>
                <div>{parseFloat((_it.amount * 10).toString()).toFixed(1)}</div>
                <div>{_it.amount}</div>
                <div>{_it.paymentGateway || 'paypal'}</div>
                <div>{getFormattedDateYearTimeFromUTC(_it.createdAt)}</div>
              </div>
            ))}
          </>
        )}
        {totalPaymentCount > 0 ? (
          <Pagination
            
            totalItemCount={totalPaymentCount}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
        ) : (
          <p className='mt-3 mb-3 no-payment'>No Payment to show.</p>
        )}

      </div>
    </div>
  )
}

export default PaymentsAdmin