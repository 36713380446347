import axios from '../config/axios'


export const createFeedback = async (postBody: any) => {
  const _url = '/feedback'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.post(_url, postBody, _config)
    // console.log('createFeecback res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('createFeecback error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getAvailableFeedbackId = async () => {
  const _url = '/feedback/available-id'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    // console.log('getAvailableFeedbackId res.data: ', data)
    return data?.data || ''
  } catch (_error: any) {
    console.log('getAvailableFeedbackId error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getAllFeedbacks = async () => {
  const _url = '/feedback'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    return data?.data || []
  } catch (_error: any) {
    console.log('getFeedbacksByFilter error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getFeedbacksByFilter = async (body: any) => {
  const _url = '/feedback/filter'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.post(_url, body, _config)
    // console.log('getFeedbacksByFilter res.data: ', data)
    return data?.data || []
  } catch (_error: any) {
    console.log('getFeedbacksByFilter error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


export const deleteFeedbackById = async (feedbackId: any) => {
  const _url = '/feedback/' + feedbackId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.delete(_url, _config)
    console.log('deleteFeedbackById res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('deleteFeedbackById error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const updateFeedbackById = async (feedbackId: any, _postBody: any) => {
  const _url = '/feedback/' + feedbackId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.put(_url, _postBody, _config)
    console.log('updateFeedbackById res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('updateFeedbackById error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}


export const uploadSingleAttachment = async (file: any, feedbackId: string) => {
  const _url = '/feedback/attachment/' + feedbackId
  
  const _config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
  }
  try {
    
    const formData = new FormData();
    formData.append('file', file)
    const { data } = await axios.put(_url, formData, _config)
    console.log(' uploadSingleAttachment res.data: ', data)
    
    return true //data && data.success
  } catch (_error: any) {
    console.log(' uploadSingleAttachment error: ', _error)
    const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
    return {
      error: true,
      message: msg
    }
  }
}