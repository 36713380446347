import { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  dashboardFriendsAvatars,
  gameDemoCategories,
  demoGames
} from '../utils/demodata'
import { getFriends } from '../apis/chat'

import '../styles/dashboard.css'
import { getAllGameInfo, getThumbById } from '../apis/game'
import RootContext from '../context/RootContext'
import { getCurrentUser } from '../apis/auth'
import { getBaseURL, getFormattedDateYearTimeFromUTC } from '../utils/helper'

const Dashboard = () => {
  // let avatarID = Math.floor((Math.random() * dashboardFriendsAvatars.length) + 1);

  const navigate = useNavigate()
  const {
    currentUser,
    totalFriends,
    setTotalFriends,
    setSelectedFriendIdforChatOpen,
    setIsChatOpen
  } = useContext(RootContext)

  const [activeTab, setActiveTab] = useState('Continue')
  const [allGames, setAllGames] = useState<any>([])
  const [filteredGames, setFilteredGames] = useState<any>([])
  const [thumbs, setThumbs] = useState<any>([])
  const [friends, setFriends] = useState<any>([])


  useEffect(() => {
    // console.log('base url: ', getBaseURL())
    init()
  }, [])

  useEffect(() => {
    if (currentUser?._id) {
      fetchAllFriends()
    }
  }, [currentUser, totalFriends])

  useEffect(() => {
    handleFetchingThumbs()
    // fetchAllFriends()

  }, [filteredGames])



  const fetchAllFriends = async () => {
    const data = await getFriends(currentUser._id);
    // console.log('friends: ', data)
    setFriends(data)
    setTotalFriends(data.length)
  }

  const init = async () => {
    const _games = await getAllGameInfo()
    setAllGames(_games)
    setFilteredGames(_games)// temp

  }


  const handleFetchingThumbs = async () => {

    const _tempThumbs = Array.from({ length: filteredGames.length })
    for (let _in = 0; _in < filteredGames.length; ++_in) {
      const _imgBlob: any = await getThumbById(filteredGames[_in]._id, 0)
      _tempThumbs[_in] = _imgBlob
    }
    // setIsLoading(false)
    setThumbs(_tempThumbs)
  }

  return (
    <div className="root-container">
      <div className="friends">
        <div className="friends-header">
          <div className="friends-title">
            <h2>Friends ({totalFriends > 0 ? totalFriends : 0})</h2>
          </div>
          <div className="friends-see-all">
            <Link to='/friends/all'>See All</Link>
          </div>
        </div>

        <div className="friends-avatars">

          {friends.length > 0 ? friends.map((friend: any) => (

            <div className="avatar-item" key={friend.createdAt}
              onClick={() => {
                setSelectedFriendIdforChatOpen(friend?.userIds[0]?._id)
                setIsChatOpen(true)
                // setIsFriendChatOpen(true)
              }}
            >
              <div className="av-icon">
                <img
                  alt={friend?.userIds[0]?.name}
                  src={
                    friend?.userIds[0]?.thumbExtension
                      ? `${getBaseURL()}/profile-thumb/thumb-${friend?.userIds[0]?._id + friend?.userIds[0]?.thumbExtension}`
                      : dashboardFriendsAvatars[0]["iconSRC"]
                  }
                />
              </div>
              <div>
                <p>{friend?.userIds[0]?.name}</p>
              </div>
            </div>
          )) : (
              <h3>You have not friends</h3>
          )}
        </div>

      </div>
      <div className="games-container">
        <h2>Games</h2>
        <div className="games-category">
          <div className="games-category-left">
            {gameDemoCategories.map((_cate, _in) => (
              <button
                key={_in}
                className={`btn btn-round btn-yellow ${_cate === activeTab || (activeTab === 'Continue' && _in === 0) ? 'active' : ''}`}
                onClick={() => {
                  setActiveTab(_cate)
                  if (_cate === 'Continue') {
                    setFilteredGames(allGames)
                  } else {
                    // setFilteredGames(demoGames.filter((_it, _in) => _it.category === _cate))
                    setFilteredGames(allGames)
                  }

                }}
              >
                {_cate}
              </button>
            ))}
          </div>
          <div className="games-category-right">
            <Link to='/games/all'>See All</Link>
          </div>
        </div>
        <div className="games-items">
          {filteredGames.map((_gmItm: any, _in: number) => (
            <div
              className="game-item"
              key={_in}
              onClick={() => navigate('/dashboard/game/' + _gmItm._id)}
            >
              <div className="game-icon">
                {thumbs?.[_in] && <img src={thumbs[_in]} alt="" />}
              </div>
              <div className="game-desc">
                <strong>{_gmItm.title}</strong>
                <p>
                  {_gmItm.updateDate ? 'Last Release: ' : '-'}
                  {_gmItm.updateDate && <strong>{getFormattedDateYearTimeFromUTC(_gmItm.updateDate)}</strong>}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Dashboard