import { useState, useEffect } from 'react'
import { getPrivacyPolicy } from '../apis/util'

import '../styles/terms-and-condition.css'

const PrivacyPolicy = () => {
  const [content, setContent] = useState('')

  useEffect(() => {
    document.title = 'PRIVACY POLICY | FLUX GAMELAND'
    initialize()
  }, [])

  const initialize = async () => {
    const _res = await getPrivacyPolicy()

    setContent(_res)
  }

  return (
    <div className='privacy-policy'>
      <h1>Privacy Policy</h1>
      <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  )
}

export default PrivacyPolicy