import {useEffect } from 'react'
import ComingSoon from '../components/ComingSoon'

import './../styles/gift-cards.css'

const GiftCards = () => {
  
  
  useEffect(() => {
  }, [])
  
  
  return (
    <div className='gift-cards-container'>
      <ComingSoon />
    </div>
  )
}

export default GiftCards