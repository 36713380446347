import { useContext, useState, useEffect } from 'react'
import RootContext from '../../context/RootContext'
import { getUsers } from '../../apis/user'
import { getAllGameInfo } from '../../apis/game'

import Loader from '../../components/Loader'
import { getFormattedDateTimeFromUTC } from '../../utils/helper'
import { Editor } from '@tinymce/tinymce-react'
import { getAllFeedbacks, updateFeedbackById } from '../../apis/feedback'
import { toast } from 'react-toastify'


import '../../styles/admin-feedback.css'

const Feedbacks = () => {
  const {
    currentUser
  } = useContext(RootContext)

  const [selectedUserId, setSelectedUserId] = useState('')
  const [selectedGameId, setSelectedGameId] = useState('')
  const [users, setUsers] = useState<any>([])
  const [games, setGames] = useState<any>([])
  const [feedbacks, setFeedbacks] = useState<any>([])
  const [feedbackLoading, setFeedbackLoading] = useState(false)
  const [feedbackState, setFeedbackState] = useState('')
  const [selectedFeedbackId, setSelectedFeedbackId] = useState('')
  const [selectedFeedback, setSelectedFeedback] = useState<any>({})

  useEffect(() => {
    if (currentUser && currentUser._id && currentUser.isAdmin) {
      init()
    }
  }, [currentUser])

  useEffect(() => {
    setSelectedFeedback(feedbacks.find((_it: any) => _it._id === selectedFeedbackId))
  }, [selectedFeedbackId, feedbacks])

  useEffect(() => {
    // console.log('selectedGameId, selectedUserId: ', selectedGameId, selectedUserId)
    if (selectedGameId && selectedUserId) {
      handleFetchFeedbacks()
    }
  }, [selectedUserId, selectedGameId])
  
  const init = async () => {
    const _users = await getUsers()
    setUsers(_users)
    if (_users.length) {
      setSelectedUserId(_users[0]._id)
    }
    
    const _games = await getAllGameInfo()
    setGames(_games)
    if (_games.length) {
      setSelectedGameId(_games[0]._id)
    }
  }

  const handleFetchFeedbacks = async () => {
    setFeedbackLoading(true) 
    const _feedbacks = await getAllFeedbacks()
    console.log('feedbacks: ', _feedbacks)
    setFeedbacks(_feedbacks)
    setFeedbackLoading(false)
  }


  const handleSubmitFeedback = async () => {
    if (!selectedGameId || !selectedUserId) {
      return
    }
    const postBody: any = {
      // gameId: selectedGameId,
      // userId: selectedUserId,
      // content: convertedFeedback,
      // from: 'admin'
      reply: feedbackState
    }

    // const _res = await createFeedback(postBody)
    const _res = await updateFeedbackById(selectedFeedbackId, postBody)
    if (_res && _res.success) {
      toast.success(_res.message)
      setFeedbacks([...feedbacks, _res.feedBack])
      setFeedbackState('')
    }
    
  }

  return (
    <div className='admin-feedback-container'>
      
      {!selectedFeedbackId ? (
        <>
          <div className="feedback-header mb-2">
            <h1 className=''>Feedbacks</h1>
            <button
              className="btn btn-yellow"
              onClick={() => handleFetchFeedbacks()}
            >
              Refresh
            </button>
          </div>
          <div className="feedback-list-container">
            {feedbackLoading && <Loader />}
            {!feedbackLoading && (
              <>
                <div className={`feedback-item item-headers`}>
                  {/* <p className='feedback-time'>{getFormattedDateTimeFromUTC(_it.createdAt)}</p>
                  <div className="feedback-content" dangerouslySetInnerHTML={{__html: _it.content}}></div> */}
                  <div>S. No</div>
                  <div>Ticket ID</div>
                  <div>Name</div>
                  <div>Email</div>
                  <div>Subject</div>
                  <div>Created</div>
                  <div></div>
                </div>
                {feedbacks.map((_it: any) => (
                  <div className={`feedback-item`} key={_it._id}>
                    <div>{_it.readableId}</div>
                    <div>{_it.feedbackId}</div>
                    <div>{_it.name}</div>
                    <div>{_it.email}</div>
                    <div>{_it.subject}</div>
                    <div>{getFormattedDateTimeFromUTC(_it.createdAt)}</div>
                    <div>
                      <button className="btn btn-yellow" onClick={() => setSelectedFeedbackId(_it._id)}>
                        Reply
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )} 
            {feedbacks.length === 0 && <p className='mt-3 mb-3 no-feedback'>No feedback to show.</p>}
          </div>
        </>
      ) : (
          <>
            <div className="feedback-header">
              <h1 className='mb-2'>Reply Feedback</h1>
              <button className="btn btn-yellow" onClick={() => setSelectedFeedbackId('')}>
                Back
              </button>
            </div>
            {selectedFeedback && selectedFeedback._id && (
              <div className={`feedback-item-details`}>
                <div className={`enter-feedback-header`}>
                  <h4 className='desc-title'>{selectedFeedback.subject}</h4>
                  <h4 className="feedback-id">#{selectedFeedback.feedbackId}</h4>
                </div>

                <div className="feedback-con-container">
                  <h4>{selectedFeedback.name}</h4>
                  <p className='feedback-time'>{getFormattedDateTimeFromUTC(selectedFeedback.createdAt)}</p>
                  {selectedFeedback.type === 'contact-feedback'
                    ? <div className="feedback-content contact-feedback">{selectedFeedback.content}</div>
                    : <div className="feedback-content" dangerouslySetInnerHTML={{ __html: selectedFeedback.content }}></div>}
                  
                </div>
              </div>
            )}


            <div className="form-group">
              <label>Enter Feedback</label>
              <Editor
                apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                init={{
                  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                }}
                // initialValue={editorState}
                value={feedbackState}
                onEditorChange={(_val) => setFeedbackState(_val)}
              />
            </div>
            <div className="submit-feedback">
              <button
                className="btn btn-xl btn-yellow"
                onClick={handleSubmitFeedback}
              >
                Submit
              </button>
            </div>
          </>
      )}
      {/*  */}
    </div>
  )
}

export default Feedbacks