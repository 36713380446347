import { useState, useEffect } from 'react'
import {
  PayPalScriptProvider,
  PayPalButtons
} from "@paypal/react-paypal-js"
import axios from '../config/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const PaypalPayment = ({
  clientId,
  cartItems,
  setCartItems,
  payAmount,
  user
}: any) => {

  const navigate = useNavigate()

  const [isPurchaseComplete, setIsPurchanseComplete] = useState(false)

  // const initEmail = 'example@example.com'

  useEffect(() => {
    if (isPurchaseComplete) {
      navigate('/marketplace')
    }
  }, [isPurchaseComplete])

  const onPaypalInit = (_: any, actions: any) => {
    actions.disable()
    actions.enable()
  }

  const createOrder = (data: any) => {
    const _url = '/order/create'
    const _postBody = { // will be changed
      cartItems,
      payAmount,
    }
    const _config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }
    return axios.post(_url, _postBody, _config)
      .then((_res: any) => {
        console.log('res: ', _res.data.orderId)
        return _res.data.orderId
      })
  }

  const approveOrder = (data: any) => {
    console.log('onApprove data: ', data)
    const _url = '/order/capture'
    const _config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }
    return axios.post(_url, data, _config)
      .then((_res: any) => {
        console.log('res: ', _res.data)
        if (_res.data && _res.data.status === 'COMPLETED') {
          toast.success('Your products are successfully purchased!')
          setIsPurchanseComplete(true)
          setCartItems([])
        }
        return _res.data
      })
  }
  
  return (
    <div className='paypal-payment'>
      <PayPalScriptProvider
        options={{
          clientId,
          // enableFunding: 'venmo',
          // dataSdkIntegrationSource: 'integrationbuilder_sc',
        }}
      >
        <PayPalButtons
          // onInit={onPaypalInit}
          createOrder={createOrder}
          onApprove={approveOrder}
        />
      </PayPalScriptProvider>
    </div>
  )
}

export default PaypalPayment