export const dashboardFriendsAvatars = [
  {
    name: 'Aragorn',
    iconSRC: require('../assets/dashboard/friend-av-0.png')
  },
  {
    name: 'Lightning',
    iconSRC: require('../assets/dashboard/friend-av-1.png')
  },
  {
    name: 'DarthVader',
    iconSRC: require('../assets/dashboard/friend-av-2.png')
  },
  {
    name: 'ShadowWolf',
    iconSRC: require('../assets/dashboard/friend-av-3.png')
  },
  {
    name: 'PrincessZelda',
    iconSRC: require('../assets/dashboard/friend-av-4.png')
  },
  {
    name: 'DragonSlayer',
    iconSRC: require('../assets/dashboard/friend-av-0.png')
  },
  {
    name: 'BlackWidow',
    iconSRC: require('../assets/dashboard/friend-av-1.png')
  },
  {
    name: 'SniperElite',
    iconSRC: require('../assets/dashboard/friend-av-2.png')
  },
  {
    name: 'TheJoker',
    iconSRC: require('../assets/dashboard/friend-av-3.png')
  },
  {
    name: 'StealthNinja',
    iconSRC: require('../assets/dashboard/friend-av-4.png')
  },
  {
    name: 'IronMan',
    iconSRC: require('../assets/dashboard/friend-av-0.png')
  }
]

export const gameDemoCategories = ['Continue', 'Action', 'Racing', 'Sports', 'Simulation', 'Puzzle']
export const demoGames = [
  {
    name: 'Batman: Arkham City',
    category: 'Action',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'God of War (2018)',
    category: 'Action',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'The Witcher 3: Wild Hunt',
    category: 'Action',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Prince of Persia: The Sands of Time',
    category: 'Action',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Shadow of the Colossus',
    category: 'Action',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Horizon Zero Dawn',
    category: 'Action',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Horizon Zero Dawn',
    category: 'Action',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Gran Turismo 4',
    category: 'Racing',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Burnout 3: Takedown',
    category: 'Racing',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Mario Kart 8 Deluxe',
    category: 'Racing',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Forza Horizon 4',
    category: 'Racing',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Wipeout HD',
    category: 'Racing',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Dirt Rally',
    category: 'Racing',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'F-Zero GX',
    category: 'Racing',
    friends: Math.floor(Math.random() * (10)) + 1
  },
  {
    name: 'Euro Truck Simulator 2',
    category: 'Simulation',
    friends: Math.floor(Math.random() * (10)) + 1
  },
]


export const marketItemsCategory = ['Initial', 'Red', 'Beard', 'Bowtie', 'Cape']


export const marketItems = [
  {
    name: 'Item Name',
    category: 'Initial',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Initial',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Initial',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Initial',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Red',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Red',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Red',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Red',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Red',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Red',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Red',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Beard',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Beard',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Beard',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Beard',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Beard',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Beard',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Beard',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Beard',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Beard',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Bowtie',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Bowtie',
    flux: Math.floor(Math.random() * (20)) + 1
  },
  {
    name: 'Item Name',
    category: 'Bowtie',
    flux: Math.floor(Math.random() * (20)) + 1
  },
]