import React from 'react'
import { useSearchParams } from 'react-router-dom'

const GameBuyClose = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const closeKey = searchParams.get('key')
  
  return (
    <div className="exit-message">
      <div className="exit-message-inner">
        {
          closeKey === 'close'
            ? 'You can safely close the browser window now and get back to the Game.'
            : closeKey === 'life-success'
              ? 'Your Life Purchase has been done. You can safely close the browser window now and get back to the Game.'
              : closeKey === 'product-success'
                ? 'Your Product Purchase has been done. You can safely close the browser window now and get back to the Game.'
                : closeKey === 'flux-success'
                  ? 'Your Flux Purchase has been done. You can safely close the browser window now and get back to the Game.'
                  : 'You can safely close the browser window now and get back to the Game.'
        }
      </div>
    </div>
  )
}

export default GameBuyClose