import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import MenuOpenIcon from './icons/MenuOpenIcon'
import { topbarData } from '../utils/navdata'
import LogoutIcon from './icons/LogoutIcon'
import BarIcon from './icons/BarIcon'
import TimesIcon from './icons/TimesIcon'
import { logout } from '../apis/auth'
import fluxIcon from '../assets/flux.svg'
import RootContext from '../context/RootContext'
import ChatBox from './ChatBox'
import mainLogo from '../assets/dashboard/logo-home.png'
import { useSocket } from '../context/SocketContext'

const Topbar = ({
  sidebarOpen,
  setSidebarOpen,
  sidebarHidden
}: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    currentUser,
    setCurrentUser
  } = useContext(RootContext)
  const {
    socket
  } = useSocket()

  const [availableLinks, setAvailableLinks] = useState<any>([])
  const [sideNavOpen, setSideNavOpen] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)


  useEffect(() => {
    // console.log('topbar current user: ', currentUser)
    const _temps: any = []
    topbarData.forEach((_item) => {
      if (_item.isAdmin && _item.isAdmin === true) {
        if (currentUser.isAdmin === true) {
          _temps.push(_item)
        }
      } else {
        _temps.push(_item)
      }
    })
    // console.log('_temps: ', _temps)
    setAvailableLinks(_temps)
  }, [currentUser])

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateScreenWidth);
    return () => window.removeEventListener('resize', updateScreenWidth)
  }, [])

  useEffect(() => {
    if (screenWidth > 768) {
      setSideNavOpen(false)
    }
  }, [screenWidth])

  return (
    <div className={`topbar ${sidebarHidden && sidebarHidden === 'yes' ? 'sidebar-hidden' : ''}`}>
      {(!sidebarHidden || sidebarHidden !== 'yes') && (
        <span
          className='menu-toggle-btn'
          onClick={() => {
            setSidebarOpen(!sidebarOpen)
          }}
        >
          <MenuOpenIcon />
        
        </span>
      )}
      <ul className='nav-list'>
        {availableLinks.map((_itm: any, _in: number) => (
          <li key={_in}>
            <Link
              to={_itm.path}
              className={`${location.pathname === _itm.path ? 'active' : ''}`}
            >
              {_itm.title}
            </Link>
          </li>
        ))}
      </ul>
      <div className="balance">
        <span>Balance: </span>
        <img src={fluxIcon} alt="Flux" />
        <strong>{parseFloat(currentUser.remainingFlux).toFixed(2) || 0}</strong>
      </div>
      <span
        className="logout-btn"
        onClick={() => {
          setCurrentUser({})
          localStorage.removeItem('user')
          localStorage.removeItem('token')
          socket?.disconnect()
          logout()
          navigate('/login')
        }}
      >
        <LogoutIcon />
      </span>

      <div
        className="nav-toggle"
        onClick={() => setSideNavOpen(!sideNavOpen)}
      >
        {sideNavOpen ? <TimesIcon /> : <BarIcon />}
      </div>

      <div className={`side-nav-list ${sideNavOpen ? 'open' : 'close'}`}>
        <ul>
          {availableLinks.map((_itm: any, _in: number) => (
            <li key={_in}>
              <Link
                to={_itm.path}
                className={`${location.pathname === _itm.path ? 'active' : ''}`}
              >
                {_itm.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      
      {sidebarHidden && sidebarHidden == 'yes' && (
        <div className="top-logo">
          <img src={mainLogo} alt="Flux Game" />
        </div>
      )}
      
      <ChatBox currentUser={currentUser} />
    </div>
  )
}

export default Topbar