import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import socketIOClient, { Socket } from 'socket.io-client';
import { getSocketURL } from '../utils/helper';

type SocketContextType = {
  socket: Socket | null;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

type SocketProviderProps = {
  children: ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null)
  const [updateKey, setUpdateKey] = useState('')
  const [updateTime, setUpdateTime] = useState('')
  const [listenerAdded, setListenderAdded] = useState(false);

  const _socketURL = getSocketURL()

  useEffect(() => {
    const newSocket = socketIOClient(_socketURL);
    newSocket.on('connect', () => {
      console.log('Socket connected');
    });
    newSocket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    // removable - just testing
    // const testingSocket = socketIOClient(_socketURL, { path: '/multiplayer' });
    // testingSocket.on('connect', () => {
    //   console.log('Multiplayer Socket connected');
    // });
    // testingSocket.on('disconnect', () => {
    //   console.log('Multiplayer Socket disconnected');
    // });
    // removable - just testing

    // Other event listeners and setup can be added here

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    // if (socket && !listenerAdded) {
    //   setListenderAdded(true)
    //   console.log('listender added: buy-from-game')
    //   socket.on('buy-confirmation', (resMsg) => {
    //     console.log('socket buy-from-game resMsg: ', resMsg)
    //   })
    // }
  }, [socket, listenerAdded])

  // const alertWithSocket = (reqMsg) => {
  //   socket.emit('update-custom', reqMsg)
  // }
//  value={{ alertWithSocket, updateKey, updateTime }}
  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context
};
