import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { getProductByIdAndToken } from '../apis/product'
import { getBaseURL } from '../utils/helper'
import starIcon from '../assets/star.png'
import fluxIcon from '../assets/flux.svg'
import '../styles/product.css'
const ProductDetails = () => {
  const location = useLocation()
  const { productId } = useParams()

  const [thumb, setThumb] = useState('')
  const [product, setProduct] = useState<any>({})

  useEffect(() => {
    console.log('productId: ', productId)
    if (productId) {
      init()
    }
  }, [productId])


  const init = async () => {
    const _prodcut = await getProductByIdAndToken(productId, localStorage.getItem('token') || '')
    console.log('-product: ', _prodcut)
    if (_prodcut && _prodcut._id) {

      setProduct(_prodcut)
    }
  }

  const getProductImgSrc = () => {
    let _url = getBaseURL()
    if (product && product._id) {
      _url += `/thumb/thumb-${product._id + product.thumbExtension}`
    }
    return _url
  }

  const getAddRemoveCartText = (_targetItem: any) => {
    // const _alreadyAdded = cartItems.find((_itm: any) => _itm._id === _targetItem._id)
    // if (_targetItem.alreadyPurchased) {
    //   return 'Already Purchased'
    // } else if (_alreadyAdded) {
    //   return 'Remove from cart'
    // }

    
    return 'Add to cart'
  }

  return (
    <div className='product-container'>
      {product && product._id && (
        <div className="product-details">
          <div className="prodcut-thumb">
            <div className="product-thumb-image">
              <img src={getProductImgSrc()} alt={product.name || ''} />
            </div>
            <div className="star-count">
              <img src={starIcon} alt="Star" /> 
              <span>1.8k</span>
            </div>
          </div>
          <div className="product-desc">
            <h2>{product.name || ''}</h2>
            <div className="product-desc-body">
              <div className="desc-row price">
                <div className="row-label">
                  <p>Price</p>
                </div>
                <div className="row-value">
                  <img src={fluxIcon} alt="Flux Icon" />
                  <span className='flux-price'>{product.price}</span>
                </div>
              </div>
              <div className="desc-row tradable">
                <div className="row-label">
                  <p>Tradable</p>
                </div>
                <div className="row-value">
                  <p>No</p>
                </div>
              </div>
              <div className="desc-row type">
                <div className="row-label">
                  <p>Type</p>
                </div>
                <div className="row-value">
                  <p>{product.category || ''}</p>
                </div>
              </div>
              <div className="desc-row description">
                <div className="row-label">
                  <p>Description</p>
                </div>
                <div className="row-value">
                  <p>{product.description || ''}</p>
                </div>
              </div>
              <div className="desc-row description">
                <div className="row-label">
                </div>
                <div className="row-value">
                  <button
                    className="btn btn-yellow active"
                    onClick={() => {}}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className="recommended-products">
        <h2 className='reco-title'>Recommended Products</h2>
        <div className="recommended-items">
          {Array.from({ length: 5 }).map((_item, _in) => (
            <div className="reco-item" key={_in}>
              <div className="reco-icon">
                
              </div>
              <div className="reco-info">
                <h4>Product Name</h4>
                <span>Category Name</span>
              </div>
            </div>
          ))}
          
        </div>
      </div> */}
    </div>
  )
}

export default ProductDetails