import React, { useEffect } from 'react'
import ToastCont from '../components/ToastCont'
import bgTopLeft from '../assets/signup/bg-03.png'
import bgBottomLeft from '../assets/signup/bg-04.png'
import bgBottomRight from '../assets/signup/bg-05.png'
import bgTopRight from '../assets/signup/bg-06.png'
import bgBottom from '../assets/signup/bg-01.png'
import diamondBg from '../assets/signup/diamond.png'
import '../styles/auth-layout.css'
import { Outlet } from 'react-router-dom'

const AuthLayout = () => {
  useEffect(() => {
    // console.log('children: ', children)
  }, [])
  
  return (
    <>
      <ToastCont />
      <div className='layout-container'>
        <div className="bg-top"></div>
        <div className="bg-middle">
          <img src={bgTopLeft} className='img-top-left' alt="backgroud-3" />
          <img src={bgTopRight} className='img-top-right' alt="backgroud-6" />
          <img src={bgBottomLeft} className='img-bottom-left' alt="backgroud-4" />
          <img src={bgBottomRight} className='img-bottom-right' alt="backgroud-5" />
        </div>
        <div className="bg-diamond">
          <img src={diamondBg} alt="Diamond" />
        </div>
        <Outlet />
        
        <div className="bg-bottom">
          <img src={bgBottom} alt="Bottom bg" />
        </div>
      </div>
    </>
  )
}

export default AuthLayout