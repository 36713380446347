import { useState, useEffect, useRef } from 'react'
import { getPrivacyPolicy } from '../apis/util'

import '../styles/contact-us.css'
import { getUserFromToken } from '../apis/user'
import { createContactMessage } from '../apis/contactUs'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { createFeedback, getAvailableFeedbackId } from '../apis/feedback'


const ContactUs = () => {
  const navigate = useNavigate()
  
  const [user, setUser] = useState<any>({});
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('')

  useEffect(() => {
    document.title = 'CONTACT US | FLUX GAMELAND'
    initialize()
  }, [])

  const initialize = async () => {
    const _user = await getUserFromToken(localStorage.getItem('token'))
    setUser(_user)
    if (_user && _user.name) {
      setUsername(_user.name)
    }
    
    if (_user && _user.name && isValidEmail(_user.name)) {
      setEmail(_user.name)
    }
  }

  const isValidEmail = (_e: any) => {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(_e);
  }


  const handleSubmitFeedback = async () => {
    if (!user?._id) {
      return
    }
    const _fId = await getAvailableFeedbackId()
    if (_fId && !_fId.error) {
        
      const postBody: any = {
        userId: user?._id,
        content: content,
        from: 'user',
        feedbackId: _fId,
        email,
        type: 'contact-feedback'
      }

      const _res: any = await createFeedback(postBody)
      if (_res && _res.success) {
        // toast.success(_res.message)
        toast.success('Your message is submitted succesfully!')
        setContent('')
      }

    }
  }
  
  return (
    <div className=' contact-us'>
      <div className="contact-us-inner">
        <h1>Contact Us</h1>
        <p className='text-white text-center mt-2'>Please contact with us using this form if you have any query, feedback and better idea about our game.</p>
        {username && <p className='text-white text-center mt-2'>You're logged in as <b>{username}</b></p>}
        <div className="form-group">
          <label>Your Email</label>
          <input
            type="text"
            placeholder='Enter Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Your Message</label>
          <textarea
            rows={8}
            placeholder='Enter Message'
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <div className="submit">
          {username ? (
            <div className='submit-cancel'>
              <button
                className="btn btn-yellow btn-xl submit-btn"
                onClick={handleSubmitFeedback}
              >
                Submit
              </button>
              <button
                className="btn btn-yellow btn-xl submit-btn"
                onClick={() => navigate('/dashboard')}
              >
                Cancel
              </button>
            </div>
          ) : (
              <p className='login-signup-redirect'>Click here to <Link to={'/login?redirect=feedback'}>Login</Link> or <Link to={'/signup?redirect=feedback'}>Sign Up</Link> to provide your feedback.</p>
          )}
        </div>
      </div>
      
    </div>
  )
}
// dangerouslySetInnerHTML={{__html: content}}
export default ContactUs