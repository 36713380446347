import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MarketplaceContext from '../context/MarketplaceContext'
import fluxIcon from '../assets/flux.png'


import '../styles/direct-buy.css'
import { Link } from 'react-router-dom'
import RootContext from '../context/RootContext'
import { createPurchase } from '../apis/purchase'
import { toast } from 'react-toastify'
const DirectBuy = () => {
  const navigate = useNavigate()
  const {
    cartItems,
    setCartItems
  }: any = useContext(MarketplaceContext)
  const {
    currentUser
  } = useContext(RootContext)

  const [cartTotal, setCartTotal] = useState(0)

  useEffect(() => {
    console.log('direct buy cartItems: ', cartItems)
    setCartTotal(cartItems.reduce((_sum: number, _itm: any) => _itm.price + _sum, 0))
  }, [cartItems])

  const handleDirectBuy = async () => {
    // to=
    const __due = Math.max(cartTotal - currentUser?.remainingFlux, 0)
    if (__due === 0) {
      const _newCartItems = cartItems.map((_it: any) => ({
        productId: _it._id,
        price: _it.price
      }))
      console.log('formatted cart items: ', _newCartItems)
      if (_newCartItems.length) {
        const _body = {
          cartItems: _newCartItems
        }
        const __token = localStorage.getItem('token') || ''
        const _resData = await createPurchase(_body, __token)
        console.log(_resData)
        if (_resData && _resData.success) {
          toast.success(_resData.message)
          setCartItems([])
        } else {
          toast.error('Something went wrong. Please try again!')
        }
      }
      
    } else {
      navigate('/marketplace/buy')
    }
  }

  return (
    <div className='root-container directbuy-container'>
      <div className="cart-preview">
        <h1 className='mb-2'>Cart Items</h1>
        {cartItems.map((_itm: any, _in: number) => (
          <div className="cart-item" key={_in}>
            <div className="cart-item-logo">
              {_itm.thumb && (
                <img src={_itm.thumb} alt={_itm.name} />
              )}
            </div>
            <div className="cart-item-title">
              <strong>{_itm.name}</strong>
            </div>
            <div className="cart-item-flux">
              <img src={fluxIcon} alt="Flux" />
              <strong>{_itm.price}</strong>
            </div>
          </div>
        ))}
        {cartItems.length === 0 && (
          <div className="cart-empty">
            <p>No Item Selected</p>
          </div>
        )}
      </div>
      <div className="cart-checkout">
        <div className="balance">
          <span className='mr-1'>Balance: </span>
          <img src={fluxIcon} alt="Flux" />
          <span>{currentUser?.remainingFlux || '0'}</span>
        </div>
        <div className="flux-info">
          <div className="flux-icon">
            <img src={fluxIcon} alt="Flux" />
          </div>
          <div className="flux-count">
            <h1>{cartTotal} Flux</h1>
            <p>Due: {Math.max(cartTotal - currentUser?.remainingFlux, 0)}</p>
          </div>
        </div>
        <div className="payment-action">
          <button className="btn btn-yellow active" onClick={handleDirectBuy}>
            Buy
          </button>
        </div>
      </div>
    </div>
  )
}

export default DirectBuy