import { useContext, useState, useEffect } from 'react'
import RootContext from '../../context/RootContext'

import { toast } from 'react-toastify'
import { updateOffer, getOffer, uploadOfferImage } from '../../apis/offer'

import '../../styles/admin-offer.css'

const Offers = () => {
  const {
    currentUser
  } = useContext(RootContext)

  const [offer, setOffer] = useState<any>('')
  const [offerURL, setOfferURL] = useState('')
  const [isOfferEnabled, setIsOfferEnabled] = useState(false)
  const [desktopThumb, setDesktopThumb] = useState<any>('')
  const [mobileThumb, setMobileThumb] = useState<any>('')
  const [initialFluxOnSignUp, setInitialFluxOnSignUp] = useState<any>('')
  const [initialRegularCoinOnSignUp, setInitialRegularCoinOnSignUp] = useState<any>('')

  useEffect(() => {
    if (currentUser && currentUser._id && currentUser.isAdmin) {
      init()
    }
  }, [currentUser])
  
  const init = async () => {
    const _offer = await getOffer()
    setOffer(_offer)
    console.log('_offer: ', _offer)
    setOfferURL(_offer.offerURL)
    setIsOfferEnabled(_offer.isEnabled)
    setInitialFluxOnSignUp(_offer.initialFluxOnSignUp)
    setInitialRegularCoinOnSignUp(_offer.initialRegularCoinOnSignUp)
  }
  
  const handleSubmitFeedback = async () => {
    
    const postBody: any = {
      isEnabled: isOfferEnabled,
      offerURL,
      initialFluxOnSignUp,
      initialRegularCoinOnSignUp
    }

    console.log('postbody: ', postBody)

    const _res = await updateOffer(postBody)
    if (_res && _res.success) {
      toast.success(_res.message)
      setOffer(_res.offer)
      
      if (desktopThumb) {
        const _res = await uploadOfferImage(desktopThumb, 'desktop')
        console.log('upload res: ', _res)
      }
      if (mobileThumb) {
        const _res = await uploadOfferImage(mobileThumb, 'mobile')
        console.log('upload res: ', _res)
      }
    }
    
  }

  return (
    <div className='admin-offer-container'>
      <h2>Offer Information</h2>
      <div className="filters">
        <div className="form-group checkbox">
          <label>Offer Enabled?</label>
          <input
            type="checkbox"
            checked={isOfferEnabled}
            onChange={(e) => setIsOfferEnabled(e.target.checked)}
          />
        </div>
        <div className="form-group">
          <label>Initial Flux Coin on Sign Up</label>
          <input
            type="number"
            value={initialFluxOnSignUp}
            placeholder='Enter Amount'
            onChange={(e) => setInitialFluxOnSignUp(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Initial Regular Coin on Sign Up</label>
          <input
            type="number"
            value={initialRegularCoinOnSignUp}
            placeholder='Enter Amount'
            onChange={(e) => setInitialRegularCoinOnSignUp(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Offer URL</label>
          <input
            type="text"
            value={offerURL}
            placeholder='Enter Offer URL'
            onChange={(e) => setOfferURL(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Select Desktop Image (1920×1080)</label>
          <input
            type="file"
            accept='image/*'
            onChange={(e) => {
              const _sFile = e.target.files?.[0]
              setDesktopThumb(_sFile)
              return
              // if (_sFile) {
              //   const reader = new FileReader();

              //   reader.onload = function (e: any) {
              //     const img: any = new Image();
              //     img.onload = function () {
              //       console.log(`Image Resolution: ${img.width} x ${img.height}`);
              //       if (img.width === 1920 && img.height === 1080) {
              //         // setDesktopThumb(_sFile)
              //       } else {
              //         toast.warn('Desktop Image must be 1920x1080 pixel.')
              //       }
              //     };

              //     img.src = e.target.result;
              //   };

              //   // Read the image as a data URL
              //   reader.readAsDataURL(_sFile)
              // }
              
            }}
          />
        </div>
        <div className="form-group">
          <label>Select Mobile Image (720x1280)</label>
          <input
            type="file"
            accept='image/*'
            onChange={(e) => {
              const _sFile = e.target.files?.[0]
              setMobileThumb(_sFile)
              return
              // if (_sFile) {
              //   const reader = new FileReader();

              //   reader.onload = function (e: any) {
              //     const img: any = new Image();
              //     img.onload = function () {
              //       console.log(`Image Resolution: ${img.width} x ${img.height}`);
              //       if (img.width === 720 && img.height === 1280) {
              //         setMobileThumb(_sFile)
              //       } else {
              //         toast.warn('Mobile Image must be 720x1280 pixel.')
              //       }
              //     };

              //     img.src = e.target.result;
              //   };

              //   // Read the image as a data URL
              //   reader.readAsDataURL(_sFile)
              // }
              
            }}
          />
        </div>
      </div>
      
      <div className="submit-offer">
        <button
          className="btn btn-xl btn-yellow"
          onClick={handleSubmitFeedback}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default Offers