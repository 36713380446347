import { useEffect, useState, useContext } from 'react'

import RootContext from '../../context/RootContext'
import { getUsersPaging, getUsersCount } from '../../apis/user'

import '../../styles/admin-users.css'
import Pagination from '../../components/Pagination'
import Loader from '../../components/Loader'
import ArrowDown from '../../components/icons/ArrowDown'
import ReactModal from 'react-modal'
import CloseIcon from '../../components/icons/CloseIcon'
import { getAllGameInfo } from '../../apis/game'

let searchTimeout: NodeJS.Timeout
const itemsPerPage = 20

const Users = () => {
  const {
    currentUser
  } = useContext(RootContext)

  const [usersLoading, setUsersLoading] = useState(true)
  const [users, setUsers] = useState<any[]>([])
  const [usersFormatted, setUsersFormatted] = useState<any[]>([])
  const [searchKey, setSearchKey] = useState('')
  const [usersCount, setUsersCount] = useState(0)
  const [sortObj, setSortObj] = useState({ key: 'readableId', sort: 'desc' })
  const [games, setGames] = useState([])
  const [playTimeWithGames, setPlayTimeWithGames] = useState<any[]>([])
  const [playTimeModal, setPlayTimeModal] = useState({
    open: false,
    selectedUser: null,
  })

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    setUsersFormatted(users.map((_usrIt: any) => {
      _usrIt.elapsedTimeStr = secondsToDHMS(_usrIt)
      return _usrIt
    }))
  }, [users])


  useEffect(() => {
    if (playTimeModal.open && playTimeModal.selectedUser) {
      handleGameAndPlayTime(playTimeModal.selectedUser)
    } else {
      setPlayTimeWithGames([])
    }
  }, [playTimeModal])

  const init = async () => {
    const _games = await getAllGameInfo()
    setGames(_games)
  }

  const handleGameAndPlayTime = async (_user: any) => {
    const _temps: any[] = []
    _user.freeJSON?.forEach((_it: any) => {
      const _game: any = games.find((_it2: any) => _it2._id === _it.gameId)
      console.log('found game: ',_it.gameId, games)
      if (_game) {
        const _timeStr = convertSecondsToDHMS(_it.json.elapsedTime)
        _temps.push({
          gameTitle: _game.title,
          timeString: _timeStr
        })
      }
    })
    setPlayTimeWithGames(_temps)
  }

  const convertSecondsToDHMS = (_totalSeconds: number) => {
    let _timeStr = ''

    const days = Math.floor(_totalSeconds / 86400);
    _totalSeconds -= days * 86400;
    const hours = Math.floor(_totalSeconds / 3600);
    _totalSeconds -= hours * 3600;
    const minutes = Math.floor(_totalSeconds / 60);
    _totalSeconds -= minutes * 60;

    if (days > 0) {
      _timeStr += days + ' Day'
      if (days > 1) {
        _timeStr += 's'
      }
      if (hours > 0) {
        _timeStr += ', '
      }
    }
    if (hours > 0) {
      _timeStr += hours + ' Hour'
      if (hours > 1) {
        _timeStr += 's'
      }
      if (minutes > 0) {
        _timeStr += ', '
      }
    }
    if (minutes > 0) {
      _timeStr += minutes + ' Minute'
      if (minutes > 1) {
        _timeStr += 's'
      }
      if (_totalSeconds > 0) {
        _timeStr += ', '
      }
    }
    if (_totalSeconds > 0) {
      _timeStr += parseInt(_totalSeconds.toString()) + ' Second'
      if (_totalSeconds > 1) {
        _timeStr += 's'
      }
    }
    // return { days, hours, minutes, seconds };
    return _timeStr
  }

  const secondsToDHMS = (_user: any) => {
    let _totalSeconds = 0
    _user.freeJSON?.forEach((_frJSN: any) => {
      if (_frJSN && _frJSN.json && _frJSN.json.elapsedTime) {
        // console.log('elapsedTime _user.name: ', _user.name, _frJSN.json.elapsedTime)
        _totalSeconds += _frJSN.json.elapsedTime
      }
    })
    return convertSecondsToDHMS(_totalSeconds)
  }


  // pagination start
  const [currentPage, setCurrentPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)

  useEffect(() => {
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(() => {
      handleFetch()
    }, 500);

  }, [searchKey])

  useEffect(() => {
    if (sortObj.key) {
      handleFetch(currentPage)
    }
  }, [sortObj])


  useEffect(() => {
    setPageCount(Math.ceil(totalItemCount / itemsPerPage))
  }, [itemsPerPage, totalItemCount])

  const handleFetch = async (page = 0) => {
    setUsersLoading(true)

    const sort: any = {}
    if (sortObj.sort) {
      sort[sortObj.key] = sortObj.sort === 'asc' ? 1 : -1
    }

    const pageBody = {
      currentPage: page,
      itemsPerPage,
      searchKey,
      sort,
    }
    console.log('pageBody: ', pageBody)
    const _data = await getUsersPaging(pageBody)

    console.log('pagination data: ', _data?.items?.length)
    if (_data?.items) {
      setUsers(_data.items?.map((_it: any, _in: number) => {
        // console.log('itemsPerPage, currentPage, _in + 1: ', itemsPerPage, page, _in + 1)
        _it.itemNo = itemsPerPage * page + (_in + 1)
        return _it
      }) || [])
      setTotalItemCount(_data?.totalCount || 0)

      setUsersCount(_data?.totalCount || 0)

    } else {

    }
    setCurrentPage(pageBody.currentPage)
    setUsersLoading(false)
  }

  const handlePageClick = async (e: any) => {
    await handleFetch(e.selected)
  }
  // pagination end 

  return (
    <div className='admin-user-container'>

      <ReactModal
        isOpen={playTimeModal.open}
        onRequestClose={() => setPlayTimeModal({
          open: false,
          selectedUser: null
        })}
      >
        <div className="profile-modal-body">
          <div className="close-modal" onClick={() => setPlayTimeModal({
            open: false,
            selectedUser: null
          })}
          >
            <CloseIcon />
          </div>
          <h2>Play Time by Each Game</h2>
          <div className="playtime-modal-body-content">
            <div className="playtime-table">
              <div className="playtime-table-row">
                <div className="table-cell title">
                  <strong>Game Name</strong>
                </div>
                <div className="table-cell title">
                  <strong>Play Time</strong>
                </div>
              </div>

              {playTimeWithGames.map((_it: any, _in) => (
                <div className="playtime-table-row" key={_in}>
                  <div className="table-cell">
                    <span>{_it.gameTitle}</span>
                  </div>
                  <div className="table-cell">
                    <span>{_it.timeString}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="delete-modal-footer">
          <button
            className='btn btn-yellow'
            onClick={() => setPlayTimeModal({
              open: false,
              selectedUser: null
            })}
          >
            Close
          </button>
        </div>

      </ReactModal>

      <div className="user-table-header">
        <h1>Users</h1>
        <h1>Total: {usersCount}</h1>
        <button
          className="btn btn-yellow"
          onClick={() => handleFetch(currentPage)}
        >
          Refresh
        </button>
      </div>
      <div className="search-right form-group">
        <label>Search Name</label>
        <input
          type="text"
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        />
      </div>
      {usersLoading ? (
        <Loader />
      ) : usersCount > 0 ? (
        <div className="user-table-container">
          <div className="user-table">
            <div className="user-table-row">
              <div
                className={`table-cell title sort ${sortObj.key === 'readableId' && sortObj.sort === 'desc' ? 'desc' : ''}`}
                onClick={() => {
                  if (sortObj.key === 'readableId' && sortObj.sort === 'desc') {
                    setSortObj({
                      key: 'readableId',
                      sort: 'asc'
                    })
                  } else {
                    setSortObj({
                      key: 'readableId',
                      sort: 'desc'
                    })
                  }
                }}
              >
                <strong>No</strong>
                <ArrowDown />
              </div>
              <div
                className={`table-cell title sort ${sortObj.key === 'name' && sortObj.sort === 'desc' ? 'desc' : ''}`}
                onClick={() => {
                  if (sortObj.key === 'name' && sortObj.sort === 'desc') {
                    setSortObj({
                      key: 'name',
                      sort: 'asc'
                    })
                  } else {
                    setSortObj({
                      key: 'name',
                      sort: 'desc'
                    })
                  }
                }}
              >
                <strong>Name</strong>
                <ArrowDown />
              </div>
              <div className="table-cell title">
                <strong>Gender</strong>
              </div>
              <div className="table-cell title">
                <strong>Nationality</strong>
              </div>
              <div className="table-cell title">
                <strong>Flux</strong>
              </div>
              <div className="table-cell title">
                <strong>Desktop Login</strong>
              </div>
              <div className="table-cell title">
                <strong>Mobile Login</strong>
              </div>
              <div
                className={`table-cell title sort ${sortObj.key === 'loginCount' && sortObj.sort === 'desc' ? 'desc' : ''}`}
                onClick={() => {
                  if (sortObj.key === 'loginCount' && sortObj.sort === 'desc') {
                    setSortObj({
                      key: 'loginCount',
                      sort: 'asc'
                    })
                  } else {
                    setSortObj({
                      key: 'loginCount',
                      sort: 'desc'
                    })
                  }
                }}
              >
                <strong>Total Login</strong>
                <ArrowDown />
              </div>
              <div
                className={`table-cell title sort ${sortObj.key === 'completedCount' && sortObj.sort === 'desc' ? 'desc' : ''}`}
                onClick={() => {
                  if (sortObj.key === 'completedCount' && sortObj.sort === 'desc') {
                    setSortObj({
                      key: 'completedCount',
                      sort: 'asc'
                    })
                  } else {
                    setSortObj({
                      key: 'completedCount',
                      sort: 'desc'
                    })
                  }
                }}
              >
                <strong>Completed Count</strong>
                <ArrowDown />
              </div>
              <div className="table-cell title">
                <strong>Play Time</strong>
              </div>
            </div>

            {usersFormatted.map((_it: any, _in) => (
              <div className="user-table-row" key={_in}>
                <div className="table-cell">
                  <span>{_it.readableId}</span>
                </div>
                <div className="table-cell">
                  <span>{_it.name}</span>
                </div>
                <div className="table-cell">
                  <span>{_it.gender || ''}</span>
                </div>
                <div className="table-cell">
                  <span>{_it?.nationality ? _it?.nationality : ''}</span>
                </div>
                <div className="table-cell">
                  <span>{parseFloat(_it.remainingFlux).toFixed(2)}</span>
                </div>
                <div className="table-cell">
                  <span>{_it.loginCountDesktop}</span>
                </div>
                <div className="table-cell">
                  <span>{_it.loginCountMobile}</span>
                </div>
                <div className="table-cell">
                  <span>{_it.loginCountDesktop + _it.loginCountMobile}</span>
                </div>
                <div className="table-cell">
                  <span>{_it.gameStats.reduce((total: number, _it2: any) => total + _it2.completedCount, 0) || '0'}</span>
                </div>
                <div className="table-cell play-time">
                  {_it.elapsedTimeStr ?
                    <>
                      <span>{_it.elapsedTimeStr}</span>
                      <span className=''>
                        <button
                          className='btn btn-yellow btn-sm'
                          onClick={() => setPlayTimeModal({
                            open: true,
                            selectedUser: _it
                          })}
                        >
                          Details
                        </button>
                      </span>
                    </> : <></>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className='mt-3 mb-3 no-data'>No User to show.</p>
      )}

      <Pagination
        totalItemCount={totalItemCount}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />

    </div>
  )
}

export default Users