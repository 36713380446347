import { getBaseURL, reqConfig } from '../utils/helper'
import axios from '../config/axios'
import fileDownload from 'js-file-download'

export const createGame = async (postBody: any) => {
  const _url = '/game'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.post(_url, postBody, _config)
    console.log('createGame res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('createGame error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const updateGame = async (postBody: any, gameId: string) => {
  const _url = '/game/' + gameId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.put(_url, postBody, _config)
    console.log('updateGame res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('updateGame error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getAllGameInfo = async () => {
  const _url = '/game'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    // console.log('getAllGameInfo res.data: ', data)
    return data?.data || []
  } catch (_error: any) {
    console.log('getAllGameInfo error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getGameById = async (gameId: string) => {
  const _url = '/game/' + gameId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    // console.log('getGameById res.data: ', data?.data)
    return data?.data || {}
  } catch (_error: any) {
    console.log('getGameById error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const uploadInstaller = async (
  file: any,
  setPercentage: any,
  gameId: string,
  platform: string
) => {
  
  const _config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    onUploadProgress: (progressEvent: any) => {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      console.log('uploaded: ', percentCompleted)
      setPercentage(percentCompleted)
      window.document.title = 'FLUX GAMELAND - ' + percentCompleted + '%'

      if (percentCompleted === 100) {
        setTimeout(() => {
          window.document.title = 'FLUX GAMELAND'
        }, 5000)
      }
    }
  }
  try {
    
    const _url = '/game/installer/' + gameId + '/' + platform 
    const formData = new FormData();
    formData.append('exe', file)
    const { data } = await axios.post(_url, formData, _config)
    console.log('uploadInstaller res.data: ', data)
    
    
    return true //data && data.success
  } catch (_error: any) {
    console.log('uploadInstaller error: ', _error)
    const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
    return {
      error: true,
      message: msg
    }
  }
}

export const downloadInstaller = async (
  gameId: string,
  platform: string,
  toast: any,
  fileName: string,
  setPercentage: any,
) => {
  
  const link = document.createElement('a');
  link.href = `${getBaseURL()}/installers/${gameId}/${platform}/${fileName}`
  link.download = fileName;
  link.click();

  await addDownloadCount(gameId)

  return
  // const _blobConfig: any = {
  //   responseType: 'blob',
  //   headers: {
  //     // 'Content-Type': 'application/octet-stream',
  //     // 'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + localStorage.getItem('token')
  //   },
  //   onDownloadProgress: (progressEvent: any) => {
  //     // const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
  //     // const current = progressEvent.currentTarget.response.length

  //     // let percentCompleted = Math.floor(current / total * 100)
  //     // console.log('completed: ', percentCompleted)
  //     var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //     console.log('parcentCompleted: ', percentCompleted)
  //     setPercentage(percentCompleted)
  //   }
  // }
  // try {
    
  //   const _url = '/game/installer/' + gameId + '/' + platform 
  //   const { data } = await axios.get(_url, _blobConfig)
  //   console.log('downloadInstaller res.data: ', data)
    
  //   const blob = data//new Blob([data], { type: 'application/octet-stream' });
  //   const _text = await blob.text()
  //   // console.log('text: ', )
  //   try {
  //     const _parsed = JSON.parse(_text)
  //     toast.error(_parsed.message || 'Something wrong when downloading the installer!')
  //     return false
  //   } catch (err0) {
  //     // const link = document.createElement('a');
  //     // link.href = window.URL.createObjectURL(blob);
  //     // link.download = fileName;
  //     // link.click();

  //     console.log('filename: ', fileName)
  //     fileDownload(blob, fileName)

  //     return true //data && data.success
  //   }
    
  //   return true
    
    
  // } catch (_error: any) {
  //   console.log('downloadInstaller error: ', _error)
  //   const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
  //   return {
  //     error: true,
  //     message: msg
  //   }
  // }
}

export const uploadThumbs = async (files: any, gameId: string) => {
  const _url = '/game/thumb/' + gameId 
  const _config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    onUploadProgress: (progressEvent: any) => {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      console.log('uploaded: ', percentCompleted)
      // setPercentage(percentCompleted)
    }
  }
  try {
    let uploadStatus = false
    let index = 0
    for (const file of files) {
      const formData = new FormData();
      formData.append('thumb', file)
      const newUrl = _url + '/' + index
      const { data } = await axios.post(newUrl, formData, _config)
      console.log('uploadThumb res.data: ', data)
      uploadStatus = data && data.success
      ++index
    }

    return uploadStatus
  } catch (_error: any) {
    console.log('uploadThumb error: ', _error)
    const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
    return {
      error: true,
      message: msg
    }
  }
}

export const getThumbById = async (gameId: string, index: any) => {
  const _url = '/game/thumb/' + gameId + '/' + index
  const _blobConfig: any = {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
  }

  // console.log('_blobConfig: ', _blobConfig)

  try {
    const { data } = await axios.get(_url, _blobConfig)
    // console.log('getThumbById res.data: ', data.type)
    
    return data && data.type !== 'application/json' ? URL.createObjectURL(data) : ''
  } catch (_error: any) {
    console.log('getThumbById error: ', _error)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const addDownloadCount = async (gameId: string) => {
  const _url = '/game/downloadcount/' + gameId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.put(_url, {}, _config)
    console.log('addDownloadCount res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('addDownloadCount error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}