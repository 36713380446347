import { getBaseURL, reqConfig } from '../utils/helper'
import axios from '../config/axios'

export const sendMessage = async (messageBody: any) => {
  const _url = '/message'
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.post(_url, messageBody, _config)
    console.log('sendMessage res.data: ', data)
    return data || {}
  } catch (_error: any) {
    console.log('sendMessage error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}

export const getMessages = async (chatId: any) => {
  const _url = '/message/' + chatId
  const _config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
  try {
    const { data } = await axios.get(_url, _config)
    console.log('getMessages res.data: ', data)
    return data || []
  } catch (_error: any) {
    console.log('getMessages error: ', _error?.response?.data)
    const __errorData = _error?.response?.data || { error: true }
    return __errorData
  }
}