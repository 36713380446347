import { reqConfig } from '../utils/helper'
import axios from '../config/axios'

export const signup = async (body: any) => {
  const _url = '/auth/signup'
  try {
    const { data } = await axios.post(_url, body, reqConfig)
    console.log('signup res.data: ', data)
    return data
  } catch (_error: any) {
    console.log('signup error: ', _error)
    const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
    return {
      error: true,
      message: msg
    }
  }
}

export const login = async (body: any) => {
  const _url = '/auth'
  try {
    const { data } = await axios.post(_url, body, reqConfig)
    console.log('login res.data: ', data)
    return data
  } catch (_error: any) {
    console.log('login error: ', _error)
    const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
    return {
      error: true,
      message: msg
    }
  }
}

export const logout = async () => {
  const _url = '/auth/logout'
  try {
    const { data } = await axios.get(_url, reqConfig)
    console.log('logout res.data: ', data)
    return data
  } catch (_error: any) {
    console.log('logout error: ', _error)
    const msg = _error?.response?.data?.message || 'Something wrong. Please try again!'
    return {
      error: true,
      message: msg
    }
  }
}

export const getCurrentUser = async () => {
  try {
    const { data } = await axios.get(
      '/auth', {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    // console.log('getCurrentUser data: ', data)
    if (data && data.message && data.message === 'authorized') {
      return data.user
    }
    return false
  } catch (_error) {
    console.log('error: ', _error)
    return false
  }
}
