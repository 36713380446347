import React, { useContext, useEffect, useState } from 'react'
import Topbar from '../components/Topbar'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import RootContext from '../context/RootContext'
import ToastCont from '../components/ToastCont'
import { getCurrentUser } from '../apis/auth'

const OnlyTopbarLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  
  const {
    currentUser,
    setCurrentUser
  } = useContext(RootContext)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  useEffect(() => {
    console.log('homeloayout location.pathname: ', location.pathname)
    init()
  }, [])

  const init = async () => {
    const _user: any = await getCurrentUser()
    if (!_user || !_user._id) {
      navigate('/login')
    } else {
      console.log('us')
      setCurrentUser(_user)
    }
  }

  return (
    <div
      className={`root-layout-container only-topbar-container ${location.pathname === '/invite' ? 'centered' : ''}`}
    >
      <ToastCont />
      <Topbar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        sidebarHidden='yes'
      />

      <Outlet />
    </div>
  )
}

export default OnlyTopbarLayout