import { useRef, useState, useEffect, useContext } from 'react'

import msgIcon from '../assets/message-square.svg'
import sendIcon from '../assets/chevrons-right.svg'

import addFriend from '../assets/add-friend.png'
import friends from '../assets/friends.png'
import '../styles/chatbox.css'
import SearchIcon from './icons/SearchIcon'
import CloseIcon from './icons/CloseIcon'
import { createOrGetSingleChat, getAllChatOfCurrentUser, toggleFriends } from '../apis/chat'
import { getMessages, sendMessage } from '../apis/message'
import { getBaseURL, getFormattedDateTimeFromUTC, getFormattedLastMsgTime, getSocketURL } from '../utils/helper'
import { getUserNameSearch } from '../apis/user'
import { useSocket } from '../context/SocketContext'
import RootContext from '../context/RootContext'
import defaultFemale from '../assets/dashboard/default-female.png'
import defaultMale from '../assets/dashboard/default-male.png'

const msgSnd = require('../assets/audios/message.mp3')

// const ENDPOINT = "http://localhost:5000"
let selectedChatCompare: any, searchTimeout: any, activeStatusInterval: any;//socket: any, 

const ChatBox = ({ currentUser }: any) => {
  const {
    setTotalFriends,
    selectedChat,
    setSelectedChat,
    isChatOpen,
    setIsChatOpen,
    selectedFriendIdforChatOpen,
    setSelectedFriendIdforChatOpen,
    messages,
    setMessages
  } = useContext(RootContext)
  const { socket } = useSocket()

  const msglistRef = useRef<any>(null)
  const msgSoundRef = useRef<any>(null)
  const friendIdsRef = useRef<any>(null)
  const allChatsRef = useRef<any>(null)

  const [messageTone] = useState(new Audio(msgSnd))

  const [userInteraction, setUserInteraction] = useState(false)
  // const [isChatOpen, setIsChatOpen] = useState(false)
  const [isFriendChatOpen, setIsFriendChatOpen] = useState(false)
  const [allChat, setAllChat] = useState<any[]>([])
  const [unreadMsgCount, setUnreadMsgCount] = useState(0)
  // const [selectedChat, setSelectedChat] = useState<any>({})
  const [messageLoading, setMessageLoading] = useState(true)
  // const [messages, setMessages] = useState<any[]>([])
  const [typedMessage, setTypedMessage] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [searchedFriends, setSearchedFriends] = useState<any>([])

  const [socketConnectionStatus, setSocketConnectionStatus] = useState('')
  const [msgListenerSet, setMsgListenerSet] = useState(false)



  useEffect(() => {
    document.addEventListener('click', () => {
      setUserInteraction(true)
    });
    return () => {
      document.removeEventListener('click', () => { });
    };
  }, [])
  useEffect(() => {
    if (currentUser?._id) {
      initFetch()
    }
  }, [currentUser])

  useEffect(() => {
    // console.log('current user changed: ', currentUser)
    initSocket(() => { })
  }, [isFriendChatOpen, currentUser, socketConnectionStatus])


  useEffect(() => {
    if (socket && !msgListenerSet) {
      socket.on("message_received", (_newMessage: any) => {
        // console.log('new message from socket: ', _newMessage.content)
        if (msgSoundRef && msgSoundRef.current && userInteraction) {
          msgSoundRef.current.click()
        }
        // console.log('selectedChatCompare: ', selectedChatCompare)
        // console.log('_newMessage.chat._id: ', _newMessage.chat._id)
        if (!selectedChatCompare || selectedChatCompare._id !== _newMessage.chat._id) { 
          const _chatFnd = allChat.find((_itm) => _itm._id === _newMessage.chat._id)
          if (_chatFnd) {
            setAllChat(allChat.map((_chatIt) => {
              if (_chatIt._id === _newMessage.chat._id) {
                if (_chatIt.userIds[0]._id === currentUser._id) {
                  _chatIt.friend = _chatIt.userIds[1]
                } else {
                  _chatIt.friend = _chatIt.userIds[0]
                }
                _chatIt.latestMessage = _newMessage
              }
              return _chatIt
            }))
          } else if (currentUser?._id) {
            initFetch()
          }

        } else {
          // message for current opened chat
          const _fnd = messages.find((_item: any) => _item._id === _newMessage._id)
          if (!_fnd) {
            setMessages((_prevs: any) => [..._prevs, _newMessage])//[...messages, _newMessage]
          }
        }

      })
      setMsgListenerSet(true)
    }

  }, [socket, msgListenerSet])

  useEffect(() => {
    let _cnt = 0
    let _friendIds: any = []
    allChat.forEach((_it: any) => {
      // console.log('chatItem: ', _it)
      const _senderId = _it?.latestMessage?.senderId?._id || _it?.latestMessage?.senderId || ''
      // if ()
      if (_it?.latestMessage?.isRead === false && _senderId !== currentUser._id) {
        ++_cnt
      }
      if (_it.friend && _it.friend?._id) {
        _friendIds.push(_it.friend?._id)
      }
    })
    friendIdsRef.current = _friendIds
    allChatsRef.current = allChat
    setUnreadMsgCount(_cnt)
  }, [allChat])

  useEffect(() => {
    if (msglistRef.current) {
      msglistRef.current.scrollTop = msglistRef.current.scrollHeight
    }
  }, [messages])

  useEffect(() => {
    // console.log('socketConnectionStatus: ', socketConnectionStatus)
  }, [socketConnectionStatus])

  const updateActiveStatusTime = () => {
    clearInterval(activeStatusInterval)
    activeStatusInterval = setInterval(() => {
      socket?.emit('update-active', currentUser?._id)
      socket?.emit('check-active', friendIdsRef.current, (res: any[]) => {
        // console.log('check active res: ', res); // ok
        const _updatedChats = allChatsRef?.current?.map((_it3: any) => {
          const _found: any = res?.find((_activeItem: any) => _it3?.friend?._id === _activeItem?.friendId)
          if (_found?.active && _found.active === true) {
            _it3.active = true
          } else {
            _it3.active = false
          }
          return _it3
        }) || []
        setAllChat(_updatedChats)
      })
      
      // console.log('friendIds from ref: ', friendIdsRef.current)
    }, 30000)//120
  }

  const initSocket = (callback: Function) => {
    // console.log('initSocket 0: ', socket?.disconnected)
    console.log('initSocket 1: ', currentUser?._id, !socketConnectionStatus, !socket?.connected)
    // if (socket?.disconnected) {
    //   socket?.connect()
    // }
    if (
      currentUser?._id &&
      socket &&
      !socketConnectionStatus &&
      !socket?.connected
    ) {
      console.log('initSocket 2')
    
      socket.emit("setup", currentUser?._id)
      setSocketConnectionStatus('pending')
      socket.on('connection', () => {
        setSocketConnectionStatus('connected')
        updateActiveStatusTime()
        if (callback) {
          callback()
        }
      })
      socket.on('error', (err: any) => {
        console.error('Socket error:', err);
      });
    }
  }

  const initFetch = async () => {
    const _chats = await getAllChatOfCurrentUser(currentUser._id)
    if (_chats && !_chats.error) {
      setAllChat(
        _chats.map((_cIt: any) => {
          if (_cIt.userIds[0]._id === currentUser._id) {
            _cIt.friend = _cIt.userIds[1]
          } else {
            _cIt.friend = _cIt.userIds[0]
          }
          _cIt.active = false
          return _cIt
        })
      )
    }

  }

  const addFriendsAndUnfriends = async (chatID: any) => {
    const isFriends = await toggleFriends(chatID);
    let cnt = 0
    setAllChat(allChat.map((chat) => {
      if (chat._id == isFriends.data._id) {
        chat.isFriends = isFriends.data.isFriends;
      }
      if (chat.isFriends == true) {
        cnt += 1
      }
      return chat;
    }))
    setTotalFriends(cnt)
  }

  const handleCreateOrGetChat = async (friendId: any) => {
    const _chat = await createOrGetSingleChat(currentUser._id, friendId)
    selectedChatCompare = _chat
    setSelectedChat(_chat)
    
    const _messages = await getMessages(_chat._id)
    setMessages(_messages)
    setMessageLoading(false)

    const _chatFnd = allChat.find((_itm) => _itm._id === _chat._id)

    if (_chatFnd) {
      setAllChat(allChat.map((_ctIt) => {

        if (_ctIt._id === _chat._id && _chat?.latestMessage?.senderId !== currentUser._id) {
          if (_ctIt?.latestMessage) {
            _ctIt.latestMessage.isRead = true
          }
        }
        return _ctIt
      }))
    } else {
      setAllChat([_chat, ...allChat])
    }



    if (socket) {
      socket.emit('join_chat', _chat._id)
    }
    setIsFriendChatOpen(true)
    setSearchedFriends([])
    setSearchKey('')
  }

  useEffect(() => {
    if (selectedFriendIdforChatOpen) {
      handleCreateOrGetChat(selectedFriendIdforChatOpen)
    }
  }, [selectedFriendIdforChatOpen])



  const handleSendMessage = async () => {
    if (!typedMessage || !typedMessage.trim()) {
      return
    }

    const msgBody = {
      content: typedMessage,
      chatId: selectedChatCompare._id,
      senderId: currentUser._id
    }

    const _sendRes = await sendMessage(msgBody)

    setMessages([...messages, _sendRes])

    setAllChat(allChat.map((_chatIt) => {
      if (_chatIt._id === _sendRes.chat._id) {
        _chatIt.latestMessage = _sendRes
      }
      return _chatIt
    }))

    setTypedMessage('')

    if (socket) {
      socket.emit('new_message', _sendRes)
    }
  }

  const getFriendTitle = () => {
    if (selectedChat && selectedChat.userIds) {
      if (selectedChat.userIds[0]._id === currentUser._id) {
        return selectedChat.userIds[1]?.name || 'Friend Name'
      }
      return selectedChat.userIds[0]?.name || 'Friend Name'
    }
    return 'Friend Name'
  }

  const handleSearchFriends = async (event: any) => {
    const _value = event.target.value
    setSearchKey(_value)
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    searchTimeout = setTimeout(async () => {
      const _friends = await getUserNameSearch(currentUser._id, _value)
      setSearchedFriends(_friends)
    }, 800)
  }

  const renderMessages = () => {
    const _msgGroups: any[] = []
    let _singlegroup: any = {}

    messages.forEach((_msgItem: any) => {
      if (!_singlegroup.senderId) {
        _singlegroup = {
          senderId: _msgItem?.senderId?._id || '',
          dateTime: getFormattedDateTimeFromUTC(_msgItem.createdAt),
          messages: [
            _msgItem
          ]
        }

      } else if (_singlegroup?.senderId === _msgItem?.senderId?._id) {

        _singlegroup.messages.push(_msgItem)
      } else {
        _msgGroups.push(_singlegroup)
        _singlegroup = {
          senderId: _msgItem?.senderId?._id,
          dateTime: getFormattedDateTimeFromUTC(_msgItem.createdAt),
          messages: [
            _msgItem
          ]
        }
      }
    })

    if (_singlegroup?.senderId) {
      _msgGroups.push(_singlegroup)
    }


    return (
      <>
        {_msgGroups.map((_group) => (
          <div
            className={`message-group ${_group?.senderId !== currentUser._id ? 'friend' : ''}`}
            key={Math.random().toString()}
          >
            <div className="date-time">
              <span>{_group.dateTime || ''}</span>
            </div>
            {_group.messages.map((_msg: any) => (
              <div className="single-message" key={Math.random().toString()}>
                <p>{_msg.content}</p>
              </div>
            ))}

          </div>
        ))}
      </>
    )
  }

  const renderLastMessage = (_latestMessage: any) => {

    const _msgText = _latestMessage?.content && _latestMessage.content.length > 20
      ? _latestMessage.content.substring(0, 20) + '...'
      : _latestMessage?.content
        ? _latestMessage.content
        : 'No message yet'

    if (
      _latestMessage && (
        _latestMessage.senderId === currentUser._id
        || _latestMessage.senderId?._id === currentUser._id
      )
    ) {
      return (
        <>
          <strong className='text-purple'>
            You:
          </strong>
          {' ' + _msgText}
        </>
      )
    }

    if (_latestMessage && _latestMessage.isRead === true) {
      return _msgText
    }

    return (
      <strong>
        {_msgText}
      </strong>
    )
  }

  return (
    <div className={`chat-container ${isChatOpen ? 'chat-open' : ''}`}>
      <div
        style={{ display: 'none' }}
        onClick={() => messageTone.play()}
        ref={msgSoundRef}
      ></div>
      <div
        className="chat-btn"
        onClick={() => {
          setIsFriendChatOpen(false)
          if (isChatOpen) {
            selectedChatCompare = null
            setSelectedChat({})
            setMessages([])
            setSelectedFriendIdforChatOpen()
          }
          setIsChatOpen(!isChatOpen)
        }}
      >
        <img src={msgIcon} alt="Msg Icon" />
        <CloseIcon />
        {unreadMsgCount > 0 && (
          <div className="chat-count">
            <strong>{unreadMsgCount}</strong>
          </div>
        )}
      </div>
      {isChatOpen && (
        <div className="chat-friends">
          <div className="freind-header">
            <h3>Live Chat</h3>
          </div>
          <div className="friends-container">
            <div className="search">
              <SearchIcon />
              <input
                type="text"
                className='text-purple'
                placeholder='Search for friends'
                value={searchKey}
                onChange={handleSearchFriends}
                onBlur={() => {
                  setTimeout(() => {
                    setSearchedFriends([])
                  }, 1000)
                }}
              />
              {searchedFriends.length > 0 && (
                <div className="search-list">
                  {searchedFriends.map((_item: any) => (
                    <div
                      className="search-item"
                      key={_item._id}
                      onClick={() => {
                        setSelectedFriendIdforChatOpen(_item._id)
                      }}
                    >
                      <p className='text-purple'>{_item?.name || ''}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="friends-list">
              {allChat.map((_it, _in) => {
                // console.log('_it.latestMessage: ', _it.latestMessage)
                return (
                  <div className="friends">

                    <div
                      className="friend-item"
                      key={_in}
                      onClick={() => {
                        if (_it.friend) {
                          // handleCreateOrGetChat(_it.friend._id)
                          setSelectedFriendIdforChatOpen(_it.friend._id)
                        }

                      }}
                    >
                      <div className="thumb">
                        <div className="thumb-image">
                          <img
                            alt={_it?.friend?.name}
                            src={
                              _it?.friend?.thumbExtension
                                ? `${getBaseURL()}/profile-thumb/thumb-${_it?.friend?._id + _it?.friend?.thumbExtension}`
                                : _it?.friend?.gender !== "Male"
                                  ? defaultFemale
                                  : defaultMale
                            }
                          />
                        </div>
                        <div className={`active-status ${_it.active ? 'active' : ''}`}>
                          <div className="active-status-inner"></div>
                        </div>
                      </div>
                      <div className="desc">
                        {/* <strong className='friend-name'>{_it?.friend?.name || 'Friend'}</strong> */}
                        <strong className='friend-name'>{_it?.friend?.name || getFriendTitle()}</strong>
                        <div className='friend-name-bottom'>

                          <span className="last-msg">
                            {renderLastMessage(_it.latestMessage)}
                          </span>

                          <span className='last-msg-time' >
                            {_it.latestMessage ? getFormattedLastMsgTime(_it.latestMessage.createdAt) : ''}
                          </span>
                          
                        </div>
                      </div>
                      
                    </div>
                    <div className="isFriends" key={_it._id}
                      onClick={() => {
                        addFriendsAndUnfriends(_it._id)
                      }} >

                      {_it?.isFriends ? (
                        <span><img src={friends} alt='AddFriends' /> </span>
                      ) : (
                        <span><img src={addFriend} alt='AddFriends' /> </span>
                      )}
                    </div>
                  </div>
                )
              })}
              {allChat.length === 0 && (
                <p className='mt-2 mb-2 text-center'>No Chat Found</p>
              )}
            </div>
          </div>
        </div>
      )}
      {isFriendChatOpen && (
        <div className="single-details">
          <div className="freind-header">
            <h3>{getFriendTitle()}</h3>
            <div
              className="cross"
              onClick={() => {
                setSelectedChat({})
                selectedChatCompare = null
                setIsFriendChatOpen(false)
                setSelectedFriendIdforChatOpen()
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="chat-list-container" ref={msglistRef}>
            {renderMessages()}
          </div>

          <div className="message-input">
            <input
              type="text"
              placeholder='Send a message'
              value={typedMessage}
              onChange={(event) => setTypedMessage(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSendMessage()
                }
              }}
            />
            <div
              className="send-btn"
              onClick={handleSendMessage}
            >
              <img src={sendIcon} alt="Send Icon" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatBox