import { useState, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { getPrivacyPolicy, updatePrivacyPolicy } from '../../apis/util'
import { toast } from 'react-toastify'


const PrivacyPolicyAdmin = () => {
  const [editorState, setEditorState] = useState('')
  

  useEffect(() => {
    initialize()
  }, [])
  
  const initialize = async () => {
    const _res = await getPrivacyPolicy()
    // console.log(_res)
    setEditorState(_res)
    
  }

  const submitUpdate = async () => {
    console.log('editorState: ', editorState)
    const _res = await updatePrivacyPolicy(editorState)
    console.log('res: ', _res)
    if (_res.success) {
      toast.success(_res.message)
    } else {
      toast.error('Something wrong. Please try again!')
    }
  }

  return (
    <div className='admin-privacy-container'>
      <h1 className='mb-2'>Privacy Policy</h1>

      <div className="privacy-editor">
        <Editor
          apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
          init={{
            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
          }}
          // initialValue={editorState}
          value={editorState}
          onEditorChange={(_val) => setEditorState(_val)}
        />
      </div>
      <div className="button text-center mt-3">
        <button
          className="btn btn-yellow btn-xl"
          onClick={submitUpdate}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default PrivacyPolicyAdmin