import { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import genderMale from '../assets/signup/gender-boy.png'
import genderFemale from '../assets/signup/gender-girl.png'
import '../styles/signup.css'
import { signup } from '../apis/auth'
import { months, generateYearArray } from '../utils/helper'
import { Link, useSearchParams } from 'react-router-dom'
import { isUserNameAvailabe } from '../apis/user'
import CheckIcon from '../components/icons/CheckIcon'
import CloseIcon from '../components/icons/CloseIcon'

const days = Array.from({ length: 31 }, (_, index) => index + 1)
const years = generateYearArray()

const Signup = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirect')

  const [bdMonth, setBdMonth] = useState('')
  const [bdDay, setBdDay] = useState(0)
  const [bdYear, setBdYear] = useState(0)
  const [userName, setUserName] = useState('')

  const [nationality, setNationality] = useState('')
  const [password, setPassword] = useState('')
  const [gender, setGender] = useState('')

  const [isNameAvailable, setIsNameAvailable] = useState(false)
  const [signupSubmitted, setSignupSubmitted] = useState(false)

  let typingTimeOut: any = useRef()

  useEffect(() => {
    clearTimeout(typingTimeOut)
    typingTimeOut.current = setTimeout(() => {
      isUserNameAvailabe(userName).then((_available) => {
        setIsNameAvailable(_available)
      }).catch((err) => {
        // console.log('error: ', err)
        setIsNameAvailable(false)
      })
    }, 500)
  }, [userName])


  const handleSignUpSubmit = async () => {
    
    const monIndex = months.indexOf(bdMonth) + 1
    console.log('monIndex: ', monIndex)
    if (!bdMonth || !bdDay || !bdYear) {
      toast.warn('Please select birthday information')
      return
    }
    if (!userName) {
      toast.warn('Please Enter User Name')
      return
    }
    if (!nationality) {
      toast.warn('Please select Nationality')
    }
    if (!gender) {
      toast.warn('Please select Gender')
    }
    if (!password) {
      toast.warn('Please enter Password')
    }

    const signupData = {
      birthday: bdDay + '-' + monIndex + '-' + bdYear,
      name: userName,
      nationality,
      gender,
      password
    }

    console.log('signupData: ', signupData)
    // return
    setSignupSubmitted(true)
    const resData = await signup(signupData)
    if (resData.error) {
      toast.error(resData.message)
    } else {
      toast.success(resData.message)

      setBdMonth('')
      setBdDay(-1)
      setBdYear(-1)
      setUserName('')
      setNationality('')
      setPassword('')
      setGender('')
    }

    setSignupSubmitted(false)
  }

  return (
    <div className="signup-wrapper">
      <div className="signup-form">
        <h1 className='title'>Sign up and start having fun</h1>
        <div className="form-group">
          <label>Birthday</label>
          <div className="bd-dropdowns">
            <select
              name="month"
              id="bd-month"
              onChange={(e) => {
                setBdMonth(e.target.value)
              }}
            >
              <option disabled selected value="">Month</option>
              {months.map((_mon) => (
                <option value={_mon} key={_mon} selected={_mon === bdMonth}>{_mon}</option>
              ))}
            </select>
            <select
              name="day"
              id="bd-day"
              onChange={(e) => {
                setBdDay(parseInt(e.target.value))
              }}
            >
              <option disabled selected value="">Day</option>
              {days.map((_d) => (
                <option value={_d} key={_d} selected={_d === bdDay}>{_d}</option>
              ))}
            </select>
            <select
              name="year"
              id="bd-year"
              onChange={(e) => {
                setBdYear(parseInt(e.target.value))
              }}
            >
              <option disabled selected value="">Year</option>
              {years.map((_y) => (
                <option value={_y} key={_y} selected={_y === bdYear} >{_y}</option>
              ))}
            </select>
          </div>
        </div>
        <div className={`form-group ${userName && userName.length ? 'mb-0' : ''}`}>
          <label htmlFor="">Username / Email</label>
          <input
            type="text"
            placeholder="Don't use your real name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <div className="username-status">
            {userName && userName.length && isNameAvailable ? (
              <p className='text-end'><span><CheckIcon /></span> <span>Available</span></p>
            ) : userName && userName.length ? (
              <p className='text-end'><span><CloseIcon /></span> <span>Not Available</span></p>
            ) : ''}
          </div>
          
        </div>
        <div className={`form-group ${userName && userName.length ? 'mt-0' : ''}`}>
          <label htmlFor="">Nationality</label>
          <input
            type="text"
            placeholder="Enter Nationality"
            value={nationality}
            onChange={(e) => setNationality(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Password</label>
          <input
            type="password"
            placeholder="At least 8 characters"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Gender (optional)</label>
          <div className="gender-btns">
            <button
              className={`btn-gender btn-male ${gender === 'Male' ? 'active' : ''}`}
              onClick={() => setGender('Male')}
            >
              <img src={genderMale} alt="Gender Male" />
            </button>
            <button
              className={`btn-gender btn-female ${gender === 'Female' ? 'active' : ''}`}
              onClick={() => setGender('Female')}
            >
              <img src={genderFemale} alt="Gender Female" />
            </button>
          </div>
        </div>
        <div className="pervacy-policy">
          <p>
            By clicking Sign Up, you are agreeing to the <Link to='/terms-and-condition'><strong className="terms-link">Terms and Condition</strong></Link> including the arbitration clause and you are acknowledging the <Link to='/privacy-policy'><strong className="privacy-link">Privacy Policy</strong></Link>
          </p>
        </div>
        <div className="form-group signup">
          <button
            className="signup-submit-btn"
            onClick={handleSignUpSubmit}
            disabled={!isNameAvailable || signupSubmitted}
          >
            { signupSubmitted ? 'Please wait' : 'Sign Up' }
          </button>
        </div>
        <div className="form-group text-center">
          <p>Already have an account? <strong><Link to={redirectTo === 'feedback' ? '/login?redirect=feedback' : '/login'}>Login</Link></strong></p>
        </div>
      </div>
    </div>
  )
}

export default Signup